/* eslint-disable @typescript-eslint/no-unused-vars */

import moment from 'moment';
import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { Table as TableComponent } from "../../../components/ui-components/table";
import data from '../data';


const Tabletitle = [
    "Quotation Number",
    "Quotation Date",
    "Customer",
    "Total",
    "SalesPerson",
    "Order Status"
]




export default function RejectedOrders() {

    const [loading, setLoading] = React.useState(false);
    const viewOrder = (id: string | number) => {
        console.log("view order", id);
    }
    return (
        <section className="mt-8">
            <table className="w-full md_table flex text-xs md:text-base table-customer bg-white">
                <thead className="bg-header md:bg-none p-2 md:p-0">
                    <TableComponent title={Tabletitle} showSelelect={true} />
                </thead>

                <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs md:text-base w-full">
                    {!loading ?
                        data.map((f: any) => {
                            return (
                                <tr className=" border-b  cursor-pointer text-license hover:bg-header hover:text-d_stock_mid_blue " key={f.email}>
                                    <td className="md:py-4 py-2 px-3  text-left  align-top block md_table text-sm" onClick={() => viewOrder(f.id)}>
                                        {f.quote_number}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-left  align-top  block md_table text-sm" onClick={() => viewOrder(f.id)}>
                                        {f.quote_date}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-left  align-top   block md_table text-sm" onClick={() => viewOrder(f.id)}>
                                        {f.customer_name}
                                    </td>
                                    <td className="md:py-4 py-2 text-left  align-top px-3  block md_table text-sm" onClick={() => viewOrder(f.id)}>
                                        {f.total}
                                    </td>
                                    <td className="md:py-4 py-2 text-left  align-top px-3  block md_table text-sm" onClick={() => viewOrder(f.id)}>
                                        {f.sales_person}
                                    </td>
                                    <td className="md:py-4 py-2 text-left  align-top px-3  block md_table text-sm" onClick={() => viewOrder(f.id)}>
                                        <span className='text-red-500'> Cancelled </span>
                                    </td>
                                </tr>
                            )
                        })
                        :
                        // loader
                        <tr>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                        </tr>
                    }
                </tbody>
            </table>
        </section>
    )
}

