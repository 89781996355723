import React, { useState } from "react";
import './index.css'

type checkboxProps = {
    label?: string
}

export function DrugStocCheckbox(props:checkboxProps){
    const [check, setCheck] = useState(false)
    return(
        <div>
        <label className="inline-flex items-center">
          <input type="checkbox" checked={check} onChange={(e) => {setCheck(e.target.checked)}} className="form-checkbox text-green-500" />
          <span className="ml-2 text-sm check__box">{props.label}</span>
        </label>
      </div>
    )
}