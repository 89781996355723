import React from 'react'
import { RiArrowDropRightLine, RiArrowDropDownLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

export const CustomerAccount: React.FC<{}> = () => {
    return (
        <>
            <div className="flex space-x-2 items-center mt-10">
                <p className='text-sm' style={{ color: '#52575e' }}>Customer Invoices</p>
                <RiArrowDropRightLine style={{ color: '#52575e' }} />
                <p className='text-sm text-d_stock_mid_blue'>SINV/2022/0083</p>
                <RiArrowDropRightLine style={{ color: '#52575e' }} />
                <Link to="#" className='text-sm text-d_stock_mid_blue underline w-1/3' style={{ letterSpacing: '0.02em' }}>Hartford Pharmacy</Link>
            </div>
            <div className="flex justify-between items-center my-12 bg-white px-12 py-8">
                <div className="flex space-x-4 items-center">
                    <button className='border border-modal py-2 px-6 rounded-full text-modal text-sm'>Create</button>
                    <button className='border border-modal py-2 px-6 rounded-full text-modal text-sm'>Edit</button>
                </div>
                <div className="flex justify-end items-center space-x-4">
                    <div className='flex items-center'>
                        <p className='text-sm text-modal'>Print</p>
                        <div>
                            <RiArrowDropDownLine className='text-modal' />
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <p className='text-sm text-modal'>1 Attachment(s)</p>
                        <div>
                            <RiArrowDropDownLine className='text-modal' />
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <p className='text-sm text-modal'>Action</p>
                        <div>
                            <RiArrowDropDownLine className='text-modal' />
                        </div>
                    </div>
                </div>
                <div className='text-sm text-modal'>Open</div>
            </div>
            <div>
                <div className="bg-white py-8 px-12">
                    <div className="flex justify-between items-center">
                        <div className='text-nav text-lg font-semibold'>SINV/2022/0083</div>
                        <div className="flex space-x-3 items-center">
                            <button className='text-sm text-license'>Send by Mail</button>
                            <button className='text-sm text-license'>Print Invoice</button>
                            <button className='text-sm text-license'>Register Payment</button>
                            <button className='text-sm text-license'>Add Credit Note</button>
                        </div>
                    </div>
                    <div className='text-base text-nav font-semibold mt-6'>Customer Info</div>
                    <div className="mt-4 flex justify-between items-center w-4/5">
                        <Link to="#" className='text-sm text-d_stock_mid_blue underline w-1/3' style={{ letterSpacing: '0.02em' }}>Hartford Pharmacy</Link>
                        <p className='text-sm  text-label '>unit 2 Hazel Court, Sule Olusesi, By Lekki  Conservation
                            center, Ajah, Lagos. Nigeria</p>
                        <p className='text-sm text-right text-label w-1/3'>+2349 864 9837 989</p>
                    </div>
                    <div className="mt-8 flex justify-between items-center w-3/4">
                        <div className=''>
                            <h1 className='text-base mb-2'>Delivery Address</h1>
                            <p className='text-sm text-label'>unit 2 Hazel Court, Sule Olusesi, By Lekki  Conservation
                                center, Ajah, Lagos. Nigeria</p>
                        </div>
                        <div className='w-1/3 text-right'>
                            <h1 className='text-base mb-2'>Invoice Date</h1>
                            <p className='text-sm text-label'>01/10/2022</p>
                        </div>

                        <div className='w-1/3 text-right'>
                            <h1 className='text-base mb-2'>Due Date</h1>
                            <p className='text-sm text-label'>01/10/2022</p>
                        </div>
                    </div>

                    <div className="mt-8 flex justify-between items-center w-1/4">
                        <div className=''>
                            <h1 className='text-base mb-2'>Sales Person</h1>
                            <p className='text-sm text-label'>Yemisi Odutona</p>
                        </div>
                        <div className=''>
                            <h1 className='text-base mb-2'>Sales Channel</h1>
                            <p className='text-sm text-label'>Point of Sale</p>
                        </div>


                    </div>
                </div>
            </div>

            <hr />
            <div className='flex items-center'>
                <button className='py-4 px-6 text-license bg-white text-xs'>INVOICE LINES</button>
                <button className='py-4 px-6 text-license text-xs'>OTHER INFO</button>
            </div>
        </>
    )
}