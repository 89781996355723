
import './style.css'
import { FiFilter } from 'react-icons/fi'
import { IoMdArrowDropdown } from 'react-icons/io'

import {FilterAccount, searchFields, SortByOption } from '../customers/data';
import { MdArticle } from "react-icons/md"

import Accounts from "./account"

import Dropdown from "../../components/ui-components/dropdown";


export default function Sales() {
    return (
        <>
            <div className="md:flex block w-full bg-white py-6 px-10 rounded justify-between items-center my-11">
                <div className="flex items-center">
                    <button
                        className="border rounded-full border-d_stock_mid_blue py-2 px-4 text-d_stock_mid_blue cursor-pointer md:text-sm text-xs"
                    >
                        <MdArticle fill="#4B70D6" size="20" className='inline' /> Create Invoice
                    </button>
                </div>
                <div className="relative text-gray-700 mx-2 w-2/5">
                    <input
                        className="lg:h-10 w-full h-8 pl-5 pr-12 py-2 outline-none placeholder-holder bg-white rounded-full border focus:shadow-outline text-sm"
                        type="text"
                        placeholder="Search for customer"
                    />
                    <div className="absolute top-0 bottom-1 right-0 flex items-center px-2 z-10 cursor-pointer">
                        <div>
                            <Dropdown
                                label={
                                    <img src='/Arrow.svg' alt='' />
                                }
                                options={[...searchFields]}
                                class={"px-2 py-0.5 text-purple-800 text-xs md:text-base ml-3 bg-blue-600 rounded-full h-6 w-6"}
                            />
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <div className='flex'>
                    <Dropdown
                        label={
                            <span className="flex lg:text-base text-license text-sm items-center">
                                Filter by
                                <FiFilter className="ml-3 h-5 hidden lg:block " />
                                <IoMdArrowDropdown className='ml-2 text-license' />
                            </span>
                        }
                        options={[...FilterAccount, {
                            label:<div>
                                <hr/>
                                <p className={"mt-3"}> Add Custom Filter </p>
                            </div>,
                            value:'custom'
                        }]}

                        class={"px-2 py-0.5 text-purple-800 rounded md:mx-3 mx-1 text-sm"}
                    />

                    <Dropdown label={
                        <span className="flex  text-sm items-center text-license">
                            Group by
                            <img src="/filter-sort.svg" alt="dropdown" className="ml-3 h-5 hidden lg:block" />
                            <IoMdArrowDropdown className='ml-2 text-license text-xs' />
                        </span>}
                        options={SortByOption}

                        class={"px-2 py-0.5 text-purple-800 text-xs md:text-base"}
                    />

                    <button
                        className="md:flex items-center hidden px-1.5 py-0.5 mx-1.5 rounded cursor-pointer  text-sm text-license"
                    >
                        <span>
                            Import
                        </span>
                        <img src="/export.svg" alt='export' className="ml-3" />
                    </button>
                </div>
            </div>
            <div>
                <Accounts />
            </div>
        </>

    )
}