import { useState } from 'react'
import './index.css'

type inputProps = {
    name?: string,
    label?: string,
    placeholder?: string,
    type?: string,
    getValue?(word: string): void,
    error?: boolean
    value?: any
    class?: string
    size?: string
}
export function DrugStocInput(props: inputProps) {
    const [dirty, setDirty] = useState(false)
    return (
        <div>
            {props.label && <label> {props.label} </label>}
            <input
                onFocus={() => setDirty(true)}
                type={props.type}
                name={props.label}
                placeholder={props.placeholder}
                onInput={(e) => props.getValue && props.getValue((e.target as any).value)}
                className={`drug-stoc-input border-solid w-full rounded-full text-sm h-8 py-2 px-4 border ${props.error && dirty && 'border-red-500'}`}
                value={props.value}
                style={{ height: props.size ? props.size : 'auto', background: '#F6F1FB' }}
            />
        </div>
    )
}



export const DrugStocInputSquare = (props: inputProps) => {
    // const [dirty, setDirty] = useState(false)
    return (
        <div className="flex ">
            <div className="mb-3 w-full">
                <input className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"  id="formFile"
                    type={props.type}
                />
            </div>

        </div >

    )
}