import moment from "moment"

export type User = {
    category: string
    discover: string
    email: string
    first_name: string
    id: number
    is_verified: boolean
    last_name: string
    location: string
    name: string
    last_purchased_date: string
    phone_no: string
    practice_license?: string
    practice_license_verification: boolean
    premise_license?: string
    premise_license_verification: boolean
}


export type UserTable = {
    date: Date | string
    name: string
    email: string
    phone: string
    status: "inactive" | "active"
    last_purchased_date: string
    account?: number
    id: number
}


export const userTableDTO = (data: any): any[] => {
    return data.map(e => ({
        name: `${e.first_name} ${e.last_name}`,
        email: e.email,
        phone: e.phone_no,
        status: e.is_verified ? 'active' : 'inactive',
        id: e.id,
        last_purchased_date: e.last_purchased_date,
        date: moment(e.created_date).format('MMMM Do YYYY')
    }))
}