/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react'

type selectProps = {
    label?: string,
    // getValue(word: string): void,
    error?: boolean
    value?: any
    class?: string
    size?: string
    otherLabel?: JSX.Element
    placeholder?: string
    options?: { value: string; label: string }[]
    handleSelect?: (value: string) => void
}


export default function Select(props: selectProps) {

    const [dirty, setDirty] = useState(false)
    return (
        <div>
            {
                props.label &&
                <div className="md:mb-2 my-2">
                    <label
                        htmlFor={props.label}
                        className={`font-light text-base ${props.error && dirty ? 'text-red-500' : 'text-nav'}`}>
                        {props.label}
                        {props.otherLabel}
                    </label>
                </div>
            }
            <select
                name="cars"
                className={props.class}
                id={props.label}
                onFocus={() => setDirty(true)}
                onChange={(e: any) => props.handleSelect ? props.handleSelect(e.target.value) : console.log(e.target.value)}
            >
                {
                    props.placeholder && <option disabled={true} selected={true}>
                        {props.placeholder}
                    </option>
                }
                {props.options?.map(option => <option value={option.value}>{option.label}</option>)}
            </select>
        </div >
    )
}
