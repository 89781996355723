import React from 'react'


type Avatarprops = {
    size: "small" | "medium" | "large" | "xlarge" | "xxlarge" | "xxxlarge";
    src?: string;
    name: string;
    radius: "rounded" | "circle" | "square";
    className?: string;
}

export default function Avatars(props: Avatarprops) {
    return (
        <div>
            {
                props.src ?
                    <div>
                        <img
                            src={props.src} alt=""
                            className={`avatar`}
                        />
                    </div> :
                    <div className={`avatar rounded bg-d_stock_purple w-8 h-8  text-xs flex items-center justify-center`}>
                        <span className="text-d_stock_card_blue ">{props.name.split(' ')[0].charAt(0)} {props.name.split(' ')[1].charAt(0)}</span>
                    </div>
            }

        </div>
    )
}
