import React from "react";
import './index.css'

type buttonProps = {
    title: string,
    type?: 'button' | 'submit',
    disabled?: boolean
}
export function DrugStocButton(props: buttonProps) {
    return (
        <div>
            <button
                type={props.type}
                className={`drugstoc__button rounded-md h-10 max-h-10 px-5 py-1 text-sm tracking-wide font-semibold w-full text-white ${props.disabled ? 'cursor-not-allowed  opacity-50' : 'cursor-pointer opacity-100'}`}>{props.title}</button>
        </div>
    )
}