import React from 'react'
import { Pie } from 'react-chartjs-2'

const data = {
    labels: [
        'Website Usage',
        'Mobile App Usage',
        'Sales Rep'
    ],
    datasets: [{
        label: 'My First Dataset',
        data: [300, 50, 100],
        backgroundColor: [
            '#FB8832',
            'rgb(54, 162, 235)',
            '#9013FE'
        ],
        hoverOffset: 4,
    }]

}

export default function PlatformAcessment() {
    return (
        <div className='w-1/3 __order_shadow bg-white'>
            <div className='flex justify-between px-8 pt-8'>
                <h1 className='text-blue-700 text-sm'>Platform Assement</h1>
                <div className='w-20'>
                    <p className='text-xs text-gray-600 text-right'>Total number of Users: </p>
                    <p className='text-xs text-right'>2000</p>
                </div>
            </div>

            <div className='p-8 text-xs'>
                <Pie data={data} options={{
                    plugins: {
                        legend: {
                            // display: false,
                            position: 'bottom',
                        }
                    }
                }} />
                <p className='text-xs mt-3.5 text-right'> Active customers : Numbers</p>
                <p className='text-xs text-right'> Inactive customer : Numbers</p>
            </div>
        </div>
    )
}
