import React from 'react';

type Analytics = {
    stats?: number | string | undefined,
    rate?: number | string | undefined,
    title: string,
    icon?: string,
    img?: string,
    amount?: number,
    active?: boolean,
    action?: any,
    index?: number

}


const AnalyticsCard = (props: Analytics) => {

    const handleClick = () => {
        if (typeof props.action === 'function') {
            props.action(props.index)
        }
    }

    return (
        <div className={`lg:w-1/4 w-full pb-8 pt-4 px-4 cursor-pointer analytics-card rounded 
             ${props.active ? 'bg-d_stock_light_blue' : ''}`
        }
            onClick={() => handleClick()}

        >
            <div className='flex items-center justify-between'>
                <div className={
                    `text-sm text-d_rate
                ${props.active ? 'text-d_stock_sec_blue text-bold' : ''}`
                }>
                    <b>{
                        props.amount ?
                            new Intl.NumberFormat("en").format(props.amount) :
                            props.stats
                    }</b>
                </div>
                <div className='text-green text-sm'>
                    +20
                </div>
            </div>
            <div className="flex mt-2">
                <div className={`text-xs text-d_rate  capitalize ${props.active ? 'text-d_stock_sec_blue font-extrabold ' : ''}`}>
                    {props.title?.toLowerCase()}
                </div>
            </div>
            {props.img ? <div>
                <img src={props.img} alt="" className="ml-2 h-8 mt-2" />
            </div> : ''}
        </div>
    );
};

export default AnalyticsCard;
