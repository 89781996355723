// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },
    {
        quote_number: 'S032810',
        quote_date: '2019-01-01',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sales_person: 'John Doe',
        order_status: 'Pending',
    },

]


export const products = [
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
]