import { NavLink } from "react-router-dom"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { responsiveActionType } from "../redux/responsive/action"
import { Transition } from "@headlessui/react"
import logout from "../assets/img/logout.svg"
// import { Home, People, Document, PaperPlus, Chat, User, Setting } from "react-iconly"
import home from '../assets/img/home-select.svg'
import chart from '../assets/img/chart.svg'
import document from "../assets/img/documents.svg"
import telemedicine from '../assets/img/tele-medicine.svg'
import user from "../assets/img/user.svg"
import settings from "../assets/img/settings.svg"
import management from "../assets/img/management.svg"

export default function Sidebar() {

    const show: boolean = useSelector((state: any) => state.responsive.show)
    const dispatch = useDispatch()

    const nav = [
        {
            link: "overview",
            // icon: <Home set="light" primaryColor="#525c5d" size="small" />,
            activeimg: home,
            img: home,
            name: "Dashboard"
        },
        {
            link: "customers",
            // icon: <People set="light" primaryColor="#525c5d" size="small" />,
            activeimg: chart,
            img: chart,
            name: "Customers"
        },
        {
            link: "sales",
            // icon: <Document set="light" primaryColor="#525c5d" size="small" />,
            activeimg: document,
            img: document,
            name: "Sales"
        },
        // {
        //     link: "procurement",
        //     // icon: <PaperPlus set="light" primaryColor="#525c5d" size="small" />,
        //     img: post,
        //     activeimg: post,
        //     name: "Procurement"
        // },
        // {
        //     link: "operations",
        //     // icon: <Chat set="two-tone" primaryColor="#525c5d" size="small" />,
        //     img: message,
        //     activeimg: message,
        //     name: "Operations"
        // },
        {
            link: "account",
            // icon: <User set="light" primaryColor="#525c5d" size="small" />,
            img: user,
            activeimg: user,
            name: "Accounts"
        },
        {
            link: "settings",
            // icon: <Setting set="broken" primaryColor="#525c5d" size="small" />,
            img: settings,
            activeimg: settings,
            name: "Settings"
        },
        {
            link: "tele-health",
            // icon: <Logout set="light" primaryColor="#0C4284" />,
            img: telemedicine,
            name: "Tele-Health"
        },
        {
            link: "management",
            // icon: <Logout set="light" primaryColor="#0C4284" />,
            img: management,
            name: "Management"
        },
        {
            link: "log-out",
            // icon: <Logout set="light" primaryColor="#0C4284" />,
            img: logout,
            name: "Log out"
        }
    ]
    const detectClick = <T, B>(e: React.MouseEvent<T, B>) => {
        const nav = window.document.querySelector('.__sidebar')
        if ((e.target as any).contains(nav)) {
            closeBar()
        }
    }

    const closeBar = () => {
        dispatch({
            type: responsiveActionType.TOGGLE_NAV,
            payload: false
        })
    }
    const [state, setstate] = useState('Good day')
    const today = new Date()
    const time = today.getHours()
    useEffect(() => {
        if (time < 12) {
            setstate('Good Morning')
        } else if (time < 16) {
            setstate('Good Afternoon')
        } else if (time < 22) {
            setstate('Good Evening')
        } else {
            setstate("Good Night")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className="md:p-10 p-5 w-1/5 hidden lg:block __side_bar_shadow">
                <div className="mb-5 text-center">
                    <img src={"/logo.svg"} alt="" className="w-28 logo inline-block" />
                </div>
                <div className="rounded-full bg-blue-400 h-20 w-20 relative flex items-center justify-center mx-auto">
                    <img src="/suncloudy.svg" alt='' className=" inline" />
                </div>
                <div>
                    <p className="text-xs text-center mt-4 text-d_rate">Hello, {state}</p>
                </div>
                <nav aria-controls="navigation" className="mt-20">
                    {nav.map((d, i) => {
                        return (<div key={i} className={"pb-3"}>
                            <NavLink to={'/dashboard/' + d.link}
                                className="px-3 py-1.5 text-sm flex items-center cursor-pointer focus:outline-none mt-3 text-gray-600"
                                activeClassName="font-semibold text-blue-500"
                            >
                                {d.img && <img src={d.img} alt="" className="w-5" />}
                                <span className="ml-5 font-thin text-lg">
                                    {d.name}
                                </span>
                            </NavLink>
                        </div>)
                    })}
                </nav>
            </div>


            <Transition
                show={show}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100 t"
                leaveTo="opacity-0">
                <div className="bg-gray-400 bg-opacity-50 w-screen fixed top-0 left-0 z-10" onClick={(e) => detectClick(e)}>
                    <div className="p-10 w-56  lg:hidden left-0 top-0 z-10 bg-white h-screen md:shadow-none shadow-lg __sidebar">
                        <img src={"/logo.svg"} alt="" className="logo inline-block mb-5 h-9" />
                        <nav aria-controls="navigation" className="mt-8">
                            {nav.map((d, i) => {
                                return (<div key={i} className={"pb-3"}>
                                    <NavLink to={d.link}
                                        className="px-3 py-1.5 rounded-full text-lg flex items-center text-d_stock_dark_blue "
                                        activeClassName="text-d_stock_mid_blue font-semibold"
                                        onClick={(e) => { closeBar() }}
                                    >
                                        <img src={d.img} alt="" className="h-6" />
                                        <span className="ml-5 font-normal text-sm"> {d.name} </span>
                                    </NavLink>
                                </div>)
                            })}
                        </nav>
                    </div>
                </div>
            </Transition>
        </>
    )
}
