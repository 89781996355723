type buttonProps = {
    title: string | JSX.Element,
    action?: () => void,
    class?: string,
    size?: 'small' | 'medium' | 'large',
    textColor?: string
    disabled?: boolean
    loading?: boolean
    paddingX?: string
    paddingY?: string
}

export default function DrugStocRoundButton(props: buttonProps) {
    return (
        <>
            {
                <button
                    className={`rounded-full flex items-center ${props.class} ${props.disabled ? 'cursor-not-allowed' : ''} ${props.paddingX ? props.paddingX : 'px-4'} ${props.paddingY ? props.paddingY : 'py-1.5'}`}
                    onClick={() => props.action ? props.action() : ''}
                    style={{ color: props.textColor, background: props.disabled ? 'gray' : '' }}
                    disabled={props.disabled || props.loading}
                >
                    {props.loading ? <span> Loading... </span> : <span> {props.title} </span>}
                </button>
            }
        </>
    )
}