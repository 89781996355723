import { useDispatch, useSelector } from "react-redux"
import { incrementAsync } from "../redux/authentication/actions";
// import { store } from "../redux/config";

export default function Notfound() {
    const dispatch = useDispatch();
    const auth = useSelector((state: any) => state.auth)

    const Add = () => {
        // dispatch({ type: 'ADD' })
        dispatch(incrementAsync())
    }
    return (
        <div>
            {auth.count} <button onClick={Add}> Add </button>
            NotFound
        </div>
    )
}