import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import { IoArrowBackOutline } from 'react-icons/io5'
import AlertModals from '../../../components/ui-components/alertModals';
import DrugStocRoundButton from '../../../components/ui-components/button-round';
import Dropdown from '../../../components/ui-components/dropdown';
import FileInput from '../../../components/ui-components/fileInput';
import DrugStocInput from '../../../components/ui-components/input';
import Fetchhook from '../../../custom-hooks/fetchhook'
import { location } from '../../../utils/locations';
import { category, discover } from '../../../utils/userdata.config';

export default function EditCustomer() {
    const { id } = useParams<any>();
    const [fetchData] = Fetchhook();
    const [userData, setUserData] = useState<any>({});
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [form, setformData] = useState<any>()
    const navigation = useHistory()

    useEffect(() => {
        fetchData({
            methods: 'get',
            path: '/api/admin/all-users/' + id,
        }).then((res: any) => {
            const response = res[0];
            const errors = res[1];

            if (errors) return toast.error(errors)

            if (response) {
                setUserData(response)
            }
        })
        // console.log('rendering')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const update = async () => {
        if (!form) {
            delete userData.premise_license
            delete userData.practice_license
        }
        const [, error] = await fetchData({
            methods: 'patch',
            path: '/api/admin/all-users/' + id,
            data: form ? form : userData
        })

        if (error) return toast.error(error)
        else {
            toast.success('Successfully Update User')
            navigation.goBack()
        }
    }

    // turns it to formData
    const addImages = (e: File, type: string) => {
        const formData = new FormData()

        const keys = Object.keys(userData)

        keys.forEach(d => {
            if (d !== 'premise_license' && d !== 'practice_license') {
                formData.append(d, userData[d])
            }
        })
        // console.log(e)

        formData.append(type, e)
        setformData(formData)
    }

    return (
        <div id="edit_biodata">
            {confirmUpdate && <AlertModals
                message={"User Data will be Updated"}
                action={() => update()}
                close={() => setConfirmUpdate(false)}
            />}
            <section className="my-10" >
                <h1 className="text-2xl"> <b>Customer Information</b> </h1>
            </section>
            <section className="bottom-1 border">
                <div className="md:py-6 md:px-8 py-3 px-4">
                    <h3> Biodata </h3>
                    <div className="md:flex mt-10 gap-8">
                        <div className="md:w-1/3 w-full ">
                            <DrugStocInput
                                type={"text"}
                                value={userData.first_name}
                                getValue={(e) => setUserData({ ...userData, first_name: e })}
                                label={"First Name"}
                            />
                        </div>
                        <div className="md:w-1/3 w-full ">
                            <DrugStocInput
                                type={"text"}
                                value={userData.last_name}
                                getValue={(e) => setUserData({ ...userData, last_name: e })}
                                label={"Last Name"}
                            />
                        </div>
                        <div className="md:w-1/3 w-full ">
                            <DrugStocInput
                                type={"email"}
                                value={userData.email}
                                getValue={(e) => setUserData({ ...userData, email: e })}
                                label={"Email"}
                            />
                        </div>
                    </div>
                    <div className="md:flex md:mt-10 gap-8">
                        <div className="md:w-1/3 w-full">
                            <DrugStocInput
                                type={"email"}
                                value={userData.phone_no}
                                getValue={(e) => setUserData({ ...userData, phone_no: e })}
                                label={"Phone Number"}
                            />
                        </div>
                        <div className="md:w-1/3 w-full">
                            <p className="mb-3 text-sm">Location: </p>
                            <Dropdown label={userData.location ? userData.location : 'Select Location'}
                                options={location}
                                handleSelect={(t: any) => { setUserData({ ...userData, location: t }) }}
                                class={`w-full px-4 py-3 text-left border rounded-full outline-none font-thin text-sm 'border-d_stock_card_blue'`}
                                full={true}
                            />
                        </div>
                        <div className="md:w-1/3 w-full">
                            <p className="mb-3 text-sm">Discover: </p>
                            <Dropdown label={userData.discover ? userData.discover : 'Select Discover From'}
                                options={discover}
                                handleSelect={(t: any) => { setUserData({ ...userData, discover: t }) }}
                                class={`w-full px-4 py-3 text-left border rounded-full outline-none font-thin text-sm 'border-d_stock_card_blue'`}
                                full={true}
                            />
                        </div>
                    </div>
                    <div className="md:flex md:mt-10 md:gap-8">
                        <div className="md:w-1/3 w-full">
                            <p className="mb-3 text-sm">Category: </p>
                            <Dropdown label={userData.category ? userData.category : 'Select Category'}
                                options={category}
                                handleSelect={(t: any) => { setUserData({ ...userData, category: t }) }}
                                class={`w-full px-4 py-3 text-left border rounded-full outline-none font-thin text-sm 'border-d_stock_card_blue'`}
                                full={true}
                            />
                        </div>
                        <div className="md:w-1/3 w-full">
                            <DrugStocInput
                                type={"text"}
                                value={userData.name}
                                getValue={(e) => setUserData({ ...userData, name: e })}
                                label={"Name of Firm"}
                            />
                        </div>
                        <div className="md:w-1/3 w-full">
                            <p>Verification Status: </p>
                            <p className="mt-3">
                                {
                                    userData!.is_verified ?
                                        <ImCheckboxChecked size={20} className="text-blue-500" onClick={() => setUserData({ ...userData, is_verified: false })} /> :
                                        <ImCheckboxUnchecked size={20} className="text-red-500" onClick={() => setUserData({ ...userData, is_verified: true })} />

                                }
                            </p>
                        </div>
                    </div>
                    <div className="md:flex gap-6 mt-11">

                        <div className="md:w-1/3 w-full">
                            <FileInput
                                fileLabel={userData.practice_license ? "Re-upload Practice License" : 'Upload Practice License'}
                                changeHandler={(file) => addImages(file, 'practice_license')}
                            />
                            <div className="flex justify-between items-center">
                                <p className="mt-2"> Practice License </p>
                                {
                                    userData.practice_license_verification ?
                                        <ImCheckboxChecked size={20} className="text-blue-500" onClick={() => setUserData({ ...userData, practice_license_verification: !userData.practice_license_verification })} /> :
                                        <ImCheckboxUnchecked size={20} className="text-red-500" onClick={() => setUserData({ ...userData, practice_license_verification: !userData.practice_license_verification })} />
                                }
                            </div>
                        </div>


                        <div className="md:w-1/3 w-full mt-5 md:m-0">
                            <FileInput
                                fileLabel={userData.premise_license ? "Re-upload Premise License" : 'Upload Premise License'}
                                changeHandler={(file) => addImages(file, 'premise_license')}
                            />
                            <div className="flex justify-between items-center">
                                <p className="mt-2"> Premise License </p>
                                {
                                    userData.premise_license_verification ?
                                        <ImCheckboxChecked size={20} className="text-blue-500" onClick={() => setUserData({ ...userData, premise_license_verification: !userData.premise_license_verification })} /> :
                                        <ImCheckboxUnchecked size={20} className="text-red-500" onClick={() => setUserData({ ...userData, premise_license_verification: !userData.premise_license_verification })} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="text-right flex md:justify-end justify-between md:max-w-max ml-auto">
                        <DrugStocRoundButton
                            title={<IoArrowBackOutline size={20} />}
                            class={"bg-gray-500 text-white text-sm  md:w-auto justify-center mt-5 md:ml-auto md:mr-9 m-0 max-w-max"}
                            action={() => navigation.goBack()}
                        />
                        <DrugStocRoundButton
                            title="Update User"
                            class={"bg-d_stock_card_blue text-white text-sm md:w-auto justify-center mt-5 md:ml-auto md:mr-9 m-0  max-w-max"}
                            action={() => setConfirmUpdate(true)}
                        />
                    </div>
                </div>
            </section>
            {/* {JSON.stringify(userData)} */}
        </div>
    )
}


// const b = {
//     "id": 574,
//     "email": "chidinmabella5@gmail.com",
//     "name": "Chocobella",
//     "first_name": "Isabella",
//     "last_name": "Eneje",
//     "category": "pharmacy",
//     "phone_no": "2347037513935",
//     "is_verified": false,
//     "location": "lagos",
//     "discover": "linkedin",
//     "practice_license": null,
//     "practice_license_verification": false,
//     "premise_license": null,
//     "premise_license_verification": false,
//     "last_purchased_date": null
// }