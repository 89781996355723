import React from 'react'
import {Popover, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { responsiveActionType } from '../redux/responsive/action';
import { MdNotificationsNone } from "react-icons/md";
import { FiMessageSquare } from "react-icons/fi";
import { RiArrowDropDownLine } from "react-icons/ri";

export default function TopNavTwo() {
    const dispatch = useDispatch()
    const show = useSelector((state: any) => state.responsive.show)
    return (
        <div className='flex justify-between items-center w-full flex-row-reverse lg:flex-row'>
            <section className="flex w-1/2 justify-between">
                <div className={"ml-5"}>
                    <Popover className="relative">
                        <Popover.Button>
                            <div className='flex items-center'>
                                <p className='text-sm text-modal'> Sales</p>
                                <div>
                                    <RiArrowDropDownLine className='text-modal' />
                                </div>
                            </div>
                        </Popover.Button>

                        <Popover.Panel className="absolute z-10 shadow p-3 bg-white mt-3 text-sm">
                            <div className=" bg-white w-32 grid gap-2">
                                <div className={""}>Document</div>
                                <div className={""}>Follow up</div>
                                <div className={""}>Master Data</div>
                            </div>
                        </Popover.Panel>
                    </Popover>
                </div>



                <div className='flex items-center'>
                    <p className='text-sm text-modal'>Purchases</p>
                    <div>
                        <RiArrowDropDownLine className='text-modal' />
                    </div>
                </div>
                <div>
                    <p className='text-sm text-modal'>Adviser</p>
                </div>
                <div>
                    <p className='text-sm text-modal'>Reporting</p>
                </div>
                <div>
                    <p className='text-sm text-modal'>Configuration</p>
                </div>
                <Transition show={!show} appear={true}>
                    <button className="hamburger block lg:hidden" onClick={() => dispatch({
                        type: responsiveActionType.TOGGLE_NAV,
                        payload: true
                    })}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </button>
                </Transition>
                <Transition show={show} appear={true}>
                    <span className="hamburger close block lg:hidden text-4xl" style={{ lineHeight: "19px", fontSize: "44px" }} onClick={() => dispatch({
                        type: responsiveActionType.TOGGLE_NAV,
                        payload: true
                    })}>
                        ×
                    </span>
                </Transition>
            </section>
            <div className="flex space-x-4 items-center">
                {/*Notification*/}
                <div className=" ">
                    <button className="md:h-10 md:w-10 h-6 w-6  flex items-center justify-center bg-transparent" >
                        <MdNotificationsNone className='font-thin' size={'25px'} fill='#61656A' />
                    </button>
                </div>
                <div className="">
                    <button className="md:h-10 md:w-10 h-6 w-6  flex items-center justify-center bg-transparent relative" >
                        <FiMessageSquare className='text-license font-thin' size={'25px'} />
                        <div className='absolute w-2 h-2 bg-d_stock_mid_blue rounded-full' style={{ top: '7px', right: '7px' }}></div>
                    </button>

                </div>
            </div>
        </div>
    )
}
