import { Redirect, Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import Overview from "../pages/overview";
import Sidebar from "../components/sidebar";
import Topnav from "../components/topnav";
import Customers from "../pages/customers";
import Accounts from "../pages/accounts";
import Operations from "../pages/operations";
import Procurement from "../pages/procurement";
import Settings from "../pages/settings";
import Sales from "../pages/sales";
import CustomerId from "../pages/customers/_id";
import EditCustomer from "../pages/customers/edit/_id";
import NewCustomer from "../pages/customers/new";
import Invoice from "../pages/accounts/_id";
import TopNavTwo from "../components/topnav-two";
import Management from "../pages/management";
import { CustomerAccount } from "../pages/accounts/_customer";
import { TeleHealth } from "../pages/tele-health";
import { TeleHealthId } from "../pages/tele-health/_id";
import { PrescriptionSummary } from "../pages/tele-health/summary/_id";

export default function Dashboard() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { path, url } = useRouteMatch()
    const location = useLocation()


    return (
        <section className={`relative `}>
            <div className={`flex justify-center `}>
                <Sidebar />
                <section className={`main-dashboard-section md:p-6 w-4/5 p-4 bg-dash_board`}>
                    <div className="w-full">
                        {
                            location.pathname.includes('account') ?
                                <TopNavTwo /> :
                                <Topnav name={location.pathname} />
                        }
                        <Switch>
                            <Route path={`${url}/overview`} exact component={Overview} />
                            <Route path={`${url}/account`} exact component={Accounts} />
                            <Route path={`${url}/customers`} exact component={Customers} />
                            <Route path={`${url}/customers/edit/:id`} component={EditCustomer} />
                            <Route path={`${url}/customers/new`} component={NewCustomer} />
                            <Route path={`${url}/customers/:id`} component={CustomerId} />
                            <Route path={`${url}/operations`} exact component={Operations} />
                            <Route path={`${url}/procurement`} exact component={Procurement} />
                            <Route path={`${url}/settings`} exact component={Settings} />
                            <Route path={`${url}/management`} exact component={Management} />
                            <Route path={`${url}/sales`} component={Sales} />
                            <Route path={`${url}/account/customer/:id`} component={CustomerAccount} />
                            <Route path={`${url}/tele-health/summary/:id`} component={PrescriptionSummary} />
                            <Route path={`${url}/tele-health/:id`} component={TeleHealthId} />
                            <Route path={`${url}/tele-health`} component={TeleHealth} />
                            <Route path={`${url}/account/:id`} component={Invoice} />

                            <Redirect path="*" to="/4-0-4" />
                        </Switch>
                    </div>
                </section>
            </div>

        </section>
    )
}
