/* eslint-disable @typescript-eslint/no-unused-vars */

import moment from 'moment';
import { useEffect, useState } from 'react'
import { GrClose } from 'react-icons/gr';
import { IoPencil } from 'react-icons/io5';
import Skeleton from 'react-loading-skeleton';
import AlertModals from '../../../components/ui-components/alertModals';
import { Table as TableComponent } from "../../../components/ui-components/table";
import Fetchhook from '../../../custom-hooks/fetchhook';
import Modal from '../../../extra-components/modals';
import { products } from '../data';


const Tabletitle = [
    "Quotation Number",
    "Quotation Date",
    "Customer",
    "Total",
    "SalesPerson",
    "Order Status"
]


const viewModalTitles = [
    "Order No",
    "Customer",
    "Total Amount",
    "Salesperson"
]


const viewOrdertitles = [
    "Products",
    "Quantity",
    "unit of measurement",
    "unit price",
    "cost",
    "Subtotal"
]

type salesData = {
    name?: string;
    amount?: number;
    author?: string;
    customer?: string;
    total_cost?: string;
    created_at?: string;
}



export default function Orders() {


    const [loading, setLoading] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [viewItem, setViewItem] = useState(false);
    const [page, setPage] = useState(1);
    const [fetchData] = Fetchhook();
    const [data, setData] = useState<salesData[]>([]);
    const [newData, setNewData] = useState(true);
    const [modalData, setModalData] = useState<salesData>({});



    const getAllData = async () => {
        try {
            setLoading(true)
            const [response, error] = await fetchData({
                methods: 'get',
                path: '/api/admin/sales?page=' + page,
            })
            if (error) {
                // setPage(page + 1)
                setPage(() => page + 1)
                return
            }
            setData([...data, ...response.results])
            if (response.result?.length < 50) {
                setNewData(false)
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    useEffect(() => {
        getAllData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])



    useEffect(() => {
        document.querySelector('.main-dashboard-section')!.addEventListener('scroll', async ({ target }: any) => {
            console.log(target.scrollHeight - target.scrollTop === target.clientHeight)
            if (target.scrollHeight - target.scrollTop < target.clientHeight) {
                if (newData && !loading) {
                    setLoading(true)
                    // eslint-disable-next-line no-const-assign
                    setPage(() => page + 1)
                }
            }
        })
    }, [loading, newData, page])

    const viewOrder = (id: string | number) => {
        console.log("view order", id);
    }

    return (
        <section className="mt-8">
            <table className="w-full md_table flex text-xs md:text-base table-customer bg-white">
                <thead className="bg-header md:bg-none p-2 md:p-0">
                    <TableComponent title={Tabletitle} showSelelect={true} />
                </thead>

                <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs md:text-base w-full">
                    {data.length ?
                        data.map((sale: any, index: number) => {
                            return (
                                <>
                                    <tr className=" border-b  cursor-pointer text-license hover:bg-header hover:text-d_stock_mid_blue " key={sale.id} onClick={() => { setViewModal(true); setModalData(data[index]) }}>
                                        <td className="md:py-4 py-2 px-3  text-left  align-top block md_table text-sm" >
                                            {sale.name}
                                        </td>
                                        <td className="md:py-4 py-2 px-3 text-left  align-top  block md_table text-sm" >
                                            {moment(sale.created_date).format('L')}
                                        </td>
                                        <td className="md:py-4 py-2 px-3 text-left  align-top   block md_table text-sm" >
                                            {sale.customer}
                                        </td>
                                        <td className="md:py-4 py-2 text-left  align-top px-3  block md_table text-sm" >
                                            {sale.total_cost}
                                        </td>
                                        <td className="md:py-4 py-2 text-left  align-top px-3  block md_table text-sm" >
                                            {sale.author}
                                        </td>
                                        <td className="md:py-4 py-2 text-left  align-top px-3  block md_table text-sm" >
                                            {sale.status}
                                        </td>
                                    </tr>

                                </>
                            )
                        })

                        :
                        // loader
                        <tr>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={5} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={5} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={5} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={5} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={5} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={5} /></td>
                        </tr>
                    }

                </tbody>
            </table>
            {loading && <div className='text-center mt-8 text-sm'> Loading ....</div>}
            <div>
                {viewModal && <Modal close={() => setViewModal(false)}>
                    <div className="bg-white">
                        <table className="w-full md_table flex text-xs md:text-base table-customer bg-white px-8">
                            <thead className="bg-header md:bg-none p-2 md:p-0">
                                <TableComponent title={viewModalTitles} showSelelect={true} />
                            </thead>
                            <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs md:text-base w-full">
                                <tr className="cursor-pointer text-license">
                                    <td className="md:py-4 py-2 pr-3 pl-8  text-left  align-top block md_table text-sm">
                                        {modalData.name}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-left text-d_stock_mid_blue underline  align-top  block md_table text-sm">
                                        {modalData.customer}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-left  align-top   block md_table text-sm">
                                        {modalData.total_cost}
                                    </td>
                                    <td className="md:py-4 py-2 pl-3 pr-8 text-left  align-top px-3  block md_table text-sm">
                                        {modalData.author}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr className='my-3 mx-8' />
                        <table className='px-8'>
                            <thead>
                                <tr className="cursor-pointer text-license">
                                    <td className="md:py-2.5 py-2 pr-3 pl-8  text-left  align-top block md_table text-xs">
                                        Delivery Address
                                    </td>
                                    <td className="md:py-2.5 py-2 px-3  text-left  align-top block md_table text-xs">
                                        Number of Items
                                    </td>
                                    <td className="md:py-2.5 py-2 pl-3 pr-8  text-left  align-top block md_table text-xs">
                                        {modalData.created_at}
                                    </td>
                                </tr>
                            </thead>
                            <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs md:text-base w-full">
                                <tr className="cursor-pointer text-license">
                                    <td className="md:py-4 py-2 pr-3 pl-8 text-left  align-top  block md_table text-sm" style={{ maxWidth: '220px' }}>
                                        Surulele, Lagos State
                                        other part of the address
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-left  align-top   block md_table text-sm">
                                        20 Items ordered
                                        <div>
                                            <span className='text-d_stock_mid_blue' onClick={() => setViewItem(true)}>
                                                View items
                                            </span>
                                        </div>
                                    </td>
                                    <td className="md:py-4 py-2 text-left pl-3 pr-8 align-top  block md_table text-sm">
                                        12/21/2021, 11:30
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div style={{ color: '#979797' }} className='mt-10 px-8'>
                            <p className='text-sm mb-1.5'> Purchase Method:  </p>
                            <p> <i className="text-license text-sm"> null </i> </p>
                        </div>
                        <p className='text-right text-license text-xs pr-8'>
                            Waiting on confimation...
                        </p>
                        <div className='text-right mt-4 pb-8 px-8'>
                            <button className='border-black border text-black py-2 px-8 rounded-full text-sm mr-6' onClick={() => setViewModal(false)}>
                                Cancel
                            </button>
                            <button className='bg-d_stock_mid_blue text-white py-2 px-8 rounded-full text-sm' onClick={() => setViewModal(false)}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </Modal>}

                {showAlert && <AlertModals
                    close={() => { setShowAlert(false) }}
                    action={() => { setShowAlert(false); setViewModal(false) }}
                    message='Confirm Order ?'
                />}
            </div>

            {
                viewItem && <Modal close={() => setViewItem(false)}>

                    <div className="bg-white relative" style={{
                        height: '80vh',
                        overflow: 'scroll'
                    }}>
                        <button onClick={() => setViewItem(false)} className='absolute p-3 right-0 cursor-pointer'>
                            <GrClose fill='black' />
                        </button>
                        <div className="flex p-8">
                            <p className="mr-10 text-sm text-license">SO32810 </p>
                            <p className='text-d_stock_mid_blue underline'>Lumaco Pharmacy</p>
                            <p className='ml-auto cursor-pointer flex items-center justify-center gap-6'> Edit <IoPencil className='inline mr-4' size={13} /> </p>
                        </div>
                        <table className="w-full md_table flex text-xs md:text-base table-customer bg-white px-8">
                            <thead className="bg-header font-light md:bg-none p-2 md:p-0">
                                <TableComponent title={viewOrdertitles} showSelelect={true} />
                            </thead>
                            <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs md:text-base w-full">
                                {!loading &&
                                    products.map((f: any) => {
                                        return (
                                            <tr
                                                className=" cursor-pointer text-nav hover:bg-header hover:text-d_stock_mid_blue "
                                                key={f.email}
                                                onClick={() => setViewModal(true)}
                                            >
                                                <td className="md:py-4 w-1/5 py-2 pl-8 pr-3  text-left  align-top block md_table text-xs " >
                                                    {f.product_name}
                                                </td>
                                                <td className="md:py-4 text-center py-2 px-3   align-top  block md_table text-xs " >
                                                    {f.quantity}
                                                </td>
                                                <td className="md:py-4 py-2 px-3 text-center  align-top   block md_table text-xs " >
                                                    {f.unit_of_measurement}
                                                </td>
                                                <td className="md:py-4 py-2 text-center  align-top px-3  block md_table text-xs " >
                                                    {f.unit_price}
                                                </td>
                                                <td className="md:py-4 py-2 text-center  align-top px-3  block md_table text-xs " >
                                                    {f.cost}
                                                </td>
                                                <td className="md:py-4 py-2 text-center  align-top pr-8 pl-3  block md_table text-xs " >
                                                    {f.sub_total}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <hr className="my-2" />
                        <div className="flex justify-between px-8 pt-4">
                            <div className="w-1/3">
                                <div className="flex text-base text-modal_label justify-between">
                                    <p> Untaxed Amount: </p>
                                    <p> ₦86,600 </p>
                                </div>
                                <div className="flex text-base text-modal_label justify-between mt-8">
                                    <p> Taxed: </p>
                                    <p> ₦0 </p>
                                </div>
                            </div>
                            <div className='w-1/3'>
                                <div className='flex text-base text-modal_label justify-between'>
                                    <p> Total: </p>
                                    <p> ₦86,600 </p>
                                </div>
                                <div className='flex text-base text-modal_label justify-between mt-8'>
                                    <p> Margin: </p>
                                    <p> ₦6,600 </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </section >
    )
}

