export const category = [
    {
        "value": "private-patient",
        "label": "Private patient"
    },
    {
        "value": "pharmacy",
        "label": "Pharmacy"
    },
    {
        "value": "doctors-office",
        "label": "Doctors Office"
    },
    {
        "value": "clinic",
        "label": "Clinic"
    },
    {
        "value": "hospital",
        "label": "Hospital"
    },
    {
        "value": "nursing-home",
        "label": "Nursing home"
    }
]

export const discover = [
    {
        "label": "linkedin",
        "value": "linkedin"
    },
    {
        "label": "instagram",
        "value": "instagram"
    },
    {
        "label": "acquisition-team",
        "value": "acquisition-team"
    },
    {
        "label": "facebook",
        "value": "facebook"
    },
    {
        "label": "google-search",
        "value": "google-search"
    },
    {
        "label": "sales-rep",
        "value": "sales-rep"
    },
    {
        "label": "referral",
        "value": "referral"
    },
    {
        "label": "others",
        "value": "others"
    }
]