/* eslint-disable @typescript-eslint/no-unused-vars */

import moment from 'moment';
import React, { useState } from 'react'

import Skeleton from 'react-loading-skeleton';

import { Table as TableComponent } from "../../components/ui-components/table";
import data from './data';
import { RiArrowDownSFill } from "react-icons/ri";
import { Link, useHistory } from 'react-router-dom';
import Dropdown from "../../components/ui-components/dropdown";
import Modal from '../../extra-components/modals';
import { DrugStocButton } from '../../extra-components/button-component';
import DrugStocRoundButton from '../../components/ui-components/button-round';

const Tabletitle = [
    "Customers",
    "Order Date",
    "Sale Person",
    "Sources Document",
    "Total",
    "Status"
]



export default function Invoice() {

    const [loading, setLoading] = useState(false);
    const history = useHistory()
    function handleClick() {
        history.push("account/:id");
    }
    const [selectedAction, setselectedAction] = useState('');




    return (
        <section className="mt-8">
            <div className="flex justify-end items-center space-x-4 mb-4">
                <Dropdown
                    handleSelect={(e: string) => setselectedAction(e)}
                    activeClass={false}
                    label={
                        <>
                            <p className='text-sm text-license'>Print
                                <RiArrowDownSFill className='text-license inline' size={15} /> </p>
                        </>
                    }
                    options={
                        [
                            {
                                label: "Invoices", value: "export",
                            },
                            {
                                label: "Invoices without payments", value: "delete",
                            },
                        ]
                    }
                />
                <Dropdown
                    handleSelect={(e: string) => setselectedAction(e)}
                    activeClass={false}
                    label={
                        <>
                            <p className='text-sm text-license'>Action
                                <RiArrowDownSFill className='text-license inline' size={15} /> </p>
                        </>
                    }
                    options={
                        [
                            {
                                label: "Export", value: "export",
                            },
                            {
                                label: "Delete", value: "delete",
                            },
                            {
                                label: "Register Payment", value: "register-payment",
                            },
                            {
                                label: 'Confirm Draft Invoices', value: 'confirm-draft-invoices'
                            }
                        ]
                    }
                />
            </div>


            {selectedAction === 'register-payment' && <Modal close={() => setselectedAction('')}>
                <div className='bg-white px-10 pb-6 pt-8 rounded '>
                    <h3 className='text-center text-2xl'>Register Payment</h3>
                    <section className='grid grid-cols-2 gap-7'>
                        <div className="mt-4">
                            <label htmlFor="payment-journal">
                                Payment Journal
                            </label>
                            <br />
                            <input id="payment-journal" className="border rounded h-9 mt-4 p-3" />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="payment-journal">
                                Payment Journal
                            </label>
                            <br />
                            <input id="payment-journal" className="border rounded h-9 mt-4 p-3" type={'date'} />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="payment-journal">
                                Payment Amount
                            </label>
                            <br />
                            <input id="payment-journal" className="border rounded h-9 mt-4 p-3" type={'number'} placeholder='₦ | 4000' />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="payment-journal">
                                Memo
                            </label>
                            <br />
                            <input id="payment-journal" className="border rounded h-9 mt-4 p-3" />
                        </div>
                    </section>
                    <div className='flex gap-5 justify-end mt-5'>
                        <DrugStocRoundButton title="Cancel" class="text-black mt-4 border-2" action={() => setselectedAction('')} />
                        <DrugStocRoundButton title="Validate" class="bg-blue-500 text-white mt-4" />
                    </div>
                </div>
            </Modal>}
            <table className="w-full md_table flex text-xs md:text-base table-customer bg-white">
                <thead className="bg-header md:bg-none p-2 md:p-0">
                    <TableComponent textAlign="text-center" title={Tabletitle} showSelelect={true} />
                </thead>

                <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs md:text-base w-full">
                    {!loading ?
                        data.map((f: any) => {
                            return (
                                <tr className=" border-b  cursor-pointer text-license hover:bg-header hover:text-d_stock_mid_blue todoLeftImportance" key={f.account} onClick={() => { handleClick() }}>
                                    <td className="md:py-4 py-2 px-3 text-center align-top items-center md_table text-sm" style={{ display: 'flex' }}>
                                        <div className="mr-2">
                                            <input type="checkbox" id="todoCheckbox" />
                                        </div>
                                        {f.account}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-center  align-top block md_table text-sm">
                                        {f.account_date}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-center  align-top block md_table text-sm" >
                                        {f.customer_name}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" >
                                        {f.sources_document}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" >
                                        {f.total}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" >
                                        {f.status}
                                    </td>
                                </tr>

                            )
                        })
                        :
                        // loader
                        <tr>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                        </tr>
                    }
                </tbody>
            </table>

        </section>
    )
}

