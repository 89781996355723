import { responsiveActionType } from "./action";

const initialState = {
    show: false
}

export const responsiveReducer = (state = initialState, payload: { type: string, payload: any }) => {
    switch (payload.type) {
        case responsiveActionType.TOGGLE_NAV:
            return { ...state, show: payload.payload }
        default:
            return state
    }
}