import { useState } from "react"
import { DrugStocInput } from "../../extra-components/input-component"

import { Link, useHistory } from 'react-router-dom'

import validator from 'validator';
import { toast } from "react-toastify";
import Fetchhook from "../../custom-hooks/fetchhook";
import { DrugStocCheckbox } from "../../extra-components/checkbox-component"
import { DrugStocButton } from "../../extra-components/button-component";

export default function Login() {
    const [email, setEmail] = useState('')
    const [staffPassword, setStaffPassword] = useState('')
    const [pending, setPending] = useState(false)
    const history = useHistory();

    const validationObject = {
        email: validator.isEmpty(email) || !validator.isEmail(email),
        staffPassword: validator.isEmpty(staffPassword),
    }

    const valid = Object.values(validationObject).includes(true)

    const [fetchDAta] = Fetchhook()

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setPending(true)
        const [response, errors] = await fetchDAta({
            methods: 'post',
            path: '/api/admin/login',
            data: {
                email: email.toLocaleLowerCase(),
                password: staffPassword
            }
        })
        setPending(false)
        if (errors) {
            toast.error(errors)
        } else {
            localStorage.setItem("token", response.token)
            toast("Login successful")
            history.push('/dashboard/overview')
        }

    }


    return (
        <>
            <div className="h-screen flex items-center justify-center login__page">
                <div className="lg:w-1/3 p-3 md:w-2/3 w-11/12 rounded ">
                    <div className="text-center mb-4">
                        <img src="/logo.svg" alt="logo" className="inline-block" />
                    </div>
                    <div className="md:p-8 p-5 rounded-2xl bg-white box__shadow">
                        <form onSubmit={(e) => submit(e)}>
                            <div className="mb-5">
                                <DrugStocInput
                                    name="email"
                                    type='email'
                                    placeholder={'ab@drugstoc.com'}
                                    getValue={(value: string) => { setEmail(value) }}
                                    error={validationObject.email}
                                />
                            </div>
                            <div className="mb-5">
                                <DrugStocInput
                                    name="password"
                                    type='password'
                                    placeholder={'*********'}
                                    getValue={(value) => setStaffPassword(value)}
                                    error={validationObject.staffPassword}
                                />
                            </div>
                            <div className="mb-5">
                                <DrugStocCheckbox label={'Remember me'} />
                            </div>
                            <div className="mb-5">
                                <DrugStocButton
                                    title="Login"
                                    disabled={valid || pending}
                                />
                            </div>
                            <p className="text-sm text-opacity-60 text-center" style={{ color: '#101010' }}>
                                Don't have an account yet?
                                <Link to="/auth/signup" className="ml-2" style={{ color: '#696ffb' }}>
                                    Sign up
                                </Link>
                            </p>
                        </form>
                    </div>

                </div>
            </div >
            {/* <ToastContainer /> */}
        </>
    )
}
