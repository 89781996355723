import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'

export default function RouteGuard(props: { children: JSX.Element }) {
    const history = useHistory()
    const token = localStorage.getItem("token")

    useEffect(() => {
        if (!token) {
            toast("Login to continue")
            history.push('/login')
        }
    }, [history, token])
    return (
        <>
            {props.children}
        </>
    )

}
