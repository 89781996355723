export const actionType = {
    ADD: 'ADD'
}

export const AddNumber = (payload?: any) => ({
    type: actionType.ADD,
    payload
})



export function incrementAsync() {
    return (dispatch: any) => {
        setTimeout(() => {
            // Yay! Can invoke sync or async actions with `dispatch`
            dispatch(AddNumber());
        }, 1000);
    };
}