import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import DrugStocRoundButton from '../../components/ui-components/button-round';
import Fetchhook from '../../custom-hooks/fetchhook'
import Skeleton from 'react-loading-skeleton';
import Initials from '../../components/ui-components/initials';
import { AiFillCheckCircle, AiFillMail } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi'
import { FiPhone } from 'react-icons/fi';
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { IoAddCircleSharp } from 'react-icons/io5';
import moment from 'moment';
import { DrugStocInputSquare } from '../../extra-components/input-component';
import { MdVisibilityOff, MdCreate } from "react-icons/md";
import { RiGlobalLine } from "react-icons/ri";
import Password from '../../components/reset-password';



export default function CustomerId() {
    const { id } = useParams<any>();
    const [fetchData] = Fetchhook();
    const [userData, setUserData] = useState<any>({});
    const navigate = useHistory()
    const [loading, setLoading] = useState(false)
    const [passwordModal, setPasswordModal] = useState(false)
    const practice_license = useRef<any>({});
    const premise_license = useRef<any>({});

    const [showEditEmail, setShowEditEmail] = useState(false)
    const [showEditPhone, setShowEditPhone] = useState(false)

    const showPasswordModal = () => {
        setPasswordModal(true)
    }
    const closeTarget = async (e: any) => {
        const doc = document.querySelector('.__password_modal')
        if (doc && e.target.contains(doc)) {
            setPasswordModal(false)
        }
    }
    useEffect(() => {
        setLoading(true);
        fetchData({
            methods: 'get',
            path: '/api/admin/all-users/' + id,
        }).then((res: any) => {
            const response = res[0];
            const errors = res[1];

            if (errors) return toast.error(errors)

            if (response) {
                setUserData(response)
            }
            setLoading(false);
        })

        // console.log('rendering')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    const saveChanges = async () => {
        try {
            let formData: any;
            if (!userData.premise_license && !userData.practice_license) {
                formData = userData
            } else if (typeof userData.practice_license === "object" || typeof userData.premise_license === "object") {
                formData = new FormData();

                const keys = Object.keys(userData);
                keys.forEach(e => {
                    if (e === "practice_license" && typeof userData[e] === "string") {
                        return
                    }
                    if (e === "premise_license" && typeof userData[e] === "string") {
                        return
                    }
                    if (userData[e]) {
                        formData.append(e, userData[e])
                    }
                })
            } else {
                formData = userData
            }
            const [response, error] = await fetchData({
                methods: "put",
                path: "/admin/all-users/" + id,
                data: formData
            })

            error ? toast.error(error) : toast.success("user profile updated successfully")

            console.log(response, error)
        } catch (error) {
            toast.error((error as any).response?.message || 'failed to update profile')
        }
    }


    const uploadFile = (type: 'premise_license' | 'practice_license') => {
        if (type === 'practice_license') setUserData({ ...userData, practice_license: practice_license.current.files[0] })
        else setUserData({ ...userData, premise_license: premise_license.current.files[0] })
    }

    return (
        <>
            <div className='relative'>
                <div className='flex items-center gap-3 my-11 text-sm text-modal_label'>
                    <BiArrowBack size={"20"} className='inline cursor-pointer text-xs text-nav' onClick={() => navigate.goBack()} /> Back
                </div>
                <div className='__side_bar_shadow'>
                    <section className="my-4 pl-8 text-center">
                        <h1 className="text-xl text-nav"> Customer Information </h1>
                    </section>
                    <section>
                        <div className="py-3 px-4 md:py-6 md:px-8">
                            <div className='flex justify-between items-center'>
                                {loading ? <Skeleton style={{ height: '120px', width: '120px', borderRadius: '100%' }} /> :
                                    <Initials initials={`${userData?.first_name} ${userData?.last_name}`} font={25} size={'lg'} />}
                                <div className="block w-5/6">
                                    <div className="w-full flex justify-between">
                                        {
                                            loading ?
                                                <Skeleton style={{ height: '40px', width: '100px' }} count={1} /> :
                                                <p className="md:m-0 ml-2 text-3xl mb-10 text-nav" style={{ letterSpacing: '0.02em' }}> {userData.first_name} {userData.last_name}</p>
                                        }
                                        <p className='text-label text-base'>Sign Up Date:<span className='text-nav ml-2 text-sm'> {moment(userData.created_date).format('D MMM Y')}</span></p>
                                        <p className=' text-nav'>  {loading ? <Skeleton style={{ height: '40px', width: '100px' }} count={1} /> :
                                            <>{
                                                userData.verified ?
                                                    <span> Verified <AiFillCheckCircle className='inline' fill={"green"} /> </span> :
                                                    <span> Unverified  </span>
                                            } </>
                                        }
                                        </p>
                                    </div>
                                    <div className='flex justify-between pt-5'>
                                        {
                                            loading ?
                                                <Skeleton style={{ height: '20px', width: '100px' }} count={1} /> :
                                                <p className="md:m-0 ml-2 text-sm text-label lowercase"> <AiFillMail className='inline mr-2' />
                                                    {userData.email}
                                                </p>
                                        }
                                        {
                                            loading ?
                                                <Skeleton style={{ height: '20px', width: '100px' }} count={1} /> :
                                                <p className="md:m-0 ml-2 text-sm capitalize text-label"> <FiPhone className='inline mr-2' />
                                                    {userData.phone_no}
                                                </p>
                                        }

                                        {
                                            loading ?
                                                <Skeleton style={{ height: '20px', width: '100px' }} count={1} /> :
                                                <p className="md:m-0 ml-2 text-sm capitalize text-label"> <HiOutlineLocationMarker className='inline mr-2' />
                                                    {userData.location || '-'}
                                                </p>
                                        }
                                        <p className='text-sm text-d_stock_mid_blue'> <IoAddCircleSharp className='inline text-d_stock_mid_blue text-sm' /> Add branches </p>
                                    </div>
                                </div>
                            </div>

                            <hr className='my-10' />


                            <p className="text-2xl font-thin text-nav"> Personal Details </p>


                            <section className='grid grid-cols-2 gap-10'>
                                <div className=' mt-4 text-nav text-base capitalize'>
                                    <span className=' text-label'>Full name of Customer:</span> {userData.first_name} {userData.last_name}
                                </div>
                                <div className='mt-4 text-nav text-base capitalize'>
                                    <span className='text-label'>Address: </span> {userData.address || '-'}
                                </div>
                                <div className='  text-nav text-base capitalize'>
                                    <span className=' text-label'>Name of Firm:</span> {userData.name}
                                </div>
                                <div className=' text-nav text-base capitalize'>
                                    <span className='text-label'>Category: </span> {userData.category}
                                </div>
                                <div className=' text-label text-base'>
                                    Practice License:
                                    {
                                        loading ?
                                            <Skeleton style={{ height: '44px', width: 'calc(100vw/3)' }} count={1} /> : <div className=" mb-5 md:mb-0">
                                                {
                                                    typeof userData.practice_license === 'string' ?
                                                        <u>
                                                            <a href={userData.practice_license} target="_blank" rel="noreferrer" className={"text-blue-500 text-xs"}>
                                                                View License
                                                            </a>
                                                        </u> :
                                                        <div className="border flex bg-file text-gray-500 text-sm w-full items-center justify-between"
                                                        >
                                                            <input ref={practice_license} type={"file"} className={"hidden"} onChange={() => uploadFile('practice_license')} />
                                                            <div className='text-xs  text-license p-2 '>
                                                                {!userData.practice_license ? 'No license upload' : userData.practice_license.name}
                                                            </div>
                                                            <button className='p-2  text-license  text-xs' style={{ background: '#DDDDDD' }} onClick={() => practice_license!.current.click()}>Upload Document</button>
                                                        </div>
                                                }
                                            </div>
                                    }
                                </div>
                                <div className=' text-label text-base'>
                                    Premise License:
                                    {
                                        loading ?
                                            <Skeleton style={{ height: '44px', width: 'calc(100vw/3)' }} count={1} /> : <div className=" mb-5 md:mb-0">
                                                {
                                                    typeof userData.premise_license === 'string' ?
                                                        <a href={userData.premise_license} target="_blank" rel="noreferrer" >
                                                            View License
                                                        </a> :
                                                        <div
                                                            className="border bg-file flex text-gray-500 text-sm w-full items-center justify-between"
                                                        >
                                                            <input ref={premise_license} type={"file"} className={"hidden"} onChange={() => uploadFile('premise_license')} />
                                                            <div className='text-xs text-license p-2'>
                                                                {!userData.premise_license ? 'No license uploaded' : userData.premise_license.name}
                                                            </div>
                                                            <button className='p-2  text-license text-xs' style={{ background: '#DDDDDD' }} onClick={() => premise_license!.current.click()}>
                                                                Upload Document
                                                            </button>
                                                        </div>
                                                }
                                            </div>
                                    }
                                </div>
                            </section>

                            <hr className='my-5' />

                            <h1 className="text-2xl font-thin text-nav"> Sign Up Details </h1>

                            <section className='grid grid-cols-2 items-center justify-center gap-10'>
                                <div className='mt-4 font-medium text-black'>
                                    <div className='flex justify-between '>
                                        <span className='text-sm text-label'>Password:</span>
                                        <button onClick={() => { showPasswordModal() }}>
                                            <i className='underline font-light text-xs text-d_stock_mid_blue'>Change</i>
                                        </button>

                                    </div>
                                    <div className='relative'>
                                        <DrugStocInputSquare value="********" type={'password'} name={''} getValue={() => { }} />
                                        <button className='absolute' style={{ top: '11px', right: '17px' }}>
                                            <MdVisibilityOff />
                                        </button>
                                    </div>


                                </div>
                                <div className='mt-4 block'>
                                    <div className=' flex text-sm text-modal_label justify-between items-center'>
                                        <span className=' w-1/3 text-label mr-3'>Email: </span>
                                        {!showEditEmail ?
                                            <>
                                                {userData.email}
                                                <button className='ml-3'>
                                                    <MdCreate onClick={() => setShowEditEmail(true)} />
                                                </button>
                                            </> :
                                            <div className={"w-full"}>
                                                <input
                                                    type="email"
                                                    className={"border bg-file flex text-gray-500 text-sm w-full items-center justify-between outline-none w-full p-1.5"}
                                                    name="email"
                                                    value={userData.email}
                                                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                                    onBlur={() => setShowEditEmail(false)}
                                                    autoFocus={true}
                                                />
                                                <br />
                                                {userData.email.length === 0 && <p className={"text-red-400 text-xs"}>  * Email is required  </p>}
                                            </div>
                                        }
                                    </div>
                                    <div className='text-sm text-modal_label flex items-center justify-between mt-6'>
                                        <span className=' w-1/3 text-label mr-3'>Phone Number: </span>
                                        {!showEditPhone ?
                                            <>
                                                {userData.phone_no}
                                                <button className='ml-3'>
                                                    <MdCreate onClick={() => {
                                                        setShowEditPhone(true);
                                                    }
                                                    } />
                                                </button>
                                            </> :
                                            <div className={"w-full"}>
                                                <input
                                                    type="tel"
                                                    className={"border bg-file flex text-gray-500 text-sm w-full items-center justify-between outline-none w-full p-1.5"}
                                                    name="phone"
                                                    value={userData.phone_no}
                                                    onChange={(e) => setUserData({ ...userData, phone_no: e.target.value })}
                                                    onBlur={() => setShowEditPhone(false)}
                                                    autoFocus={true}
                                                />
                                                <br />
                                                {userData.phone_no.length === 0 && <p className={"text-red-400 text-xs"}> * Phone no is required </p>}
                                            </div>
                                        }

                                    </div>
                                </div>

                                <div className='flex items-center space-x-2'>
                                    <div className='mt-4 text-nav text-base'>
                                        <span className=' text-label'>Platform Signed up on: </span>
                                        Web
                                    </div>
                                    <div className='mt-4'>
                                        <RiGlobalLine className='text-nav text-base' />
                                    </div>
                                </div>
                                <div className='mt-4 text-nav text-base'>
                                    <p className='text-sm text-d_stock_mid_blue'> <IoAddCircleSharp className='inline text-d_stock_mid_blue text-sm' /> Add Phone Number </p>
                                </div>

                            </section>

                            {/* {JSON.stringify(userData)} */}

                            <hr className='my-5' />
                            <div className='font-light text-nav text-lg py-5'>Account</div>
                            <hr className='my-5' />
                            <div className="flex items-center space-x-2 mt-5 justify-end">
                                <DrugStocRoundButton
                                    title="Close"
                                    class={"bg-transparent text-nav border border-modal_label text-sm w-full md:w-auto justify-center py-4 px-6"}
                                    action={() => navigate.go(-1)}
                                />
                                <DrugStocRoundButton
                                    title="Save Changes"
                                    class={"bg-transparent text-nav border border-modal_label text-sm w-full md:w-auto justify-center py-4 px-6"}
                                    action={() => saveChanges()}
                                />
                            </div>
                        </div>
                    </section >
                    {/* {JSON.stringify(userData)} */}
                    {
                        passwordModal && <div className='fixed top-0 left-0 right-0 w-full h-full dash_board flex items-center justify-center __password_modal' onClick={(e) => { closeTarget(e) }}>
                            <Password closeModal={() => setPasswordModal(false)} />
                        </div>
                    }

                </div >
            </div>
        </>
    )
}
