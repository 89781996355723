import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Fetchhook from '../../custom-hooks/fetchhook';
import AnalyticsCard from "./analytics-card";

const AnalyticsGroup = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [analytics, _] = useState([
        {
            title: "PHARMACIES",
            icon: "",
            img: '/doctors.svg',
            amount: undefined,
            stats: 1202,
            rate: 50
        },
        {
            title: "HOSPITAL/CLINICS",
            icon: "",
            img: '/hospitals.svg',
            amount: undefined,
            stats: 400,
            rate: 3
        },
        {
            title: "DOCTORS",
            icon: "",
            img: '/pharmacies.png',
            amount: undefined,
            stats: 232,
            rate: 3
        },
        {
            title: "OTHERS",
            icon: "",
            img: '',
            amount: undefined,
            stats: 76,
            rate: 3
        }
    ])
    const [active, setActive] = useState(0)

    const [fetchData] = Fetchhook()
    const [loading, setLoading] = useState(true)
    const page = 1

    const getData = async () => {
        setLoading(true)
        await fetchData({
            methods: 'get',
            path: '/api/admin/summary',
        })
        //         monthly_receivables: 0
        // total_active: 2
        // total_clinic: 0
        // total_doctors: 1
        // total_hospital: 0
        // total_inactive: 5
        // total_monthly_sales: 159386528.04000002
        // total_pharmacy: 4

        // const keys = analytics.map((e, i) => {
        //     if (i === 0) e.amount = response.total_pharmacy
        //     else if (i === 1) e.amount = response.total_hospitsl
        //     else if (i === 2) e.amount = response.total_doctors
        //     else if (i === 3) e.amount = (response.total_active + response.total_inactive) - (response.total_pharmacy + response.total_hospital + response.total_doctors)
        //     // else e.amount = '-
        //     return e
        // })

        // setAnalytics(
        //     keys
        // )

        setLoading(false)
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div className="flex md:mt-7 mt-10 space-x-4 mx-2 overflow-scroll md:overflow-none __analytics-card-holder">
            {
                !loading ? analytics.map((analytic, i) =>
                    <AnalyticsCard
                        title={analytic.title}
                        icon={analytic.icon}
                        amount={analytic.amount}
                        stats={analytic.stats} key={i}
                        active={i === active}
                        action={setActive}
                        index={i}
                        img={analytic.img}
                    />
                ) : (<>
                    <SkeletonTheme>
                        <Skeleton style={{ height: '120px' }} className="my-4 px-2 mx-2" count={5} />
                    </SkeletonTheme>
                </>
                )
            }
        </div>
    );
};

export default AnalyticsGroup;
