
// @ts-ignore
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Default from "./layouts/default";
import Dashboard from "./layouts/dashboard";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RouteGuard from './components/routeGuard';


function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/dashboard/" >
            <RouteGuard>
              <Dashboard />
            </RouteGuard>
          </Route>
          <Route component={Default} path="/auth/" />
          <Redirect to="/auth/login" />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
