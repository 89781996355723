/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
// import { toast } from 'react-toastify';
import AlertModals from '../../components/ui-components/alertModals';
import DrugStocRoundButton from '../../components/ui-components/button-round';
// import Dropdown from '../../components/extra-components/dropdown';
import FileInput from '../../components/ui-components/fileInput';
// import DrugStocInput from '../../components/extra-components/input';
import Fetchhook from '../../custom-hooks/fetchhook';
import { category, discover } from '../../utils/userdata.config';
import { location, getLGA } from '../../utils/locations';
import validator from 'validator';
import ModalInput from '../../components/ui-components/modal-input';
import Select from '../../components/ui-components/select';
import { MdVisibilityOff, MdVisibility } from "react-icons/md";
import { MdInfoOutline } from "react-icons/md";
import { IoMdAddCircle } from 'react-icons/io';
import { toast } from "react-toastify";



export default function NewCustomer() {
    const [fetchData] = Fetchhook();
    const [userData, setUserData] = useState<any>({
        email: '',
        first_name: '',
        last_name: '',
        category: '',
        phone_no: '',
        location: '',
        discover: '',
        name: '',
        password: '',
        address: '',
        local_government: '',
        state: ''
    });
    const [confirmUpdate, setConfirmUpdate] = useState(false)
    const [form, setformData] = useState<any>()
    const navigation = useHistory()
    const [loading, setLoading] = useState(false)
    const [localGovernmentAreas, setLocalGovernmentAreas] = useState<{ label: string, value: string }[]>([])
    const [showPassword, setShowingPassword] = useState(false)


    const premise_license = useRef<any>({})
    const practice_license = useRef<any>({})



    const uploadFile = (title: "premise_license" | "practice_license") => {
        if (title === "premise_license") {
            userData.premise_license = premise_license.current.files[0]
        } else {
            userData.practice_license = practice_license.current.files[0]
        }
    }



    const validationObject = {
        email: validator.isEmpty(userData.email) || !validator.isEmail(userData.email),
        address: validator.isEmpty(userData.address),
        password: validator.isEmpty(userData.password),
        first_name: validator.isEmpty(userData.first_name),
        category: validator.isEmpty(userData.category),
        phone_no: validator.isEmpty(userData.phone_no),
        location: validator.isEmpty(userData.location),
        discover: validator.isEmpty(userData.discover),
        name: validator.isEmpty(userData.name),
        local_government: validator.isEmpty(userData.local_government),
        state: validator.isEmpty(userData.state)
    }

    useEffect(() => {
        setLocalGovernmentAreas(getLGA(userData.location))
    }, [userData.location])

    // const valid = Object.values(validationObject).includes(true)


    const createNewUser = async () => {
        try {
            setLoading(true)
            if (!form) {
                delete userData.premise_license
                delete userData.practice_license
            } else {
                userData.first_name = userData.first_name.split(" ")[0]
                userData.last_name = userData.last_name.split(" ")[1]
                const keys = Object.keys(userData)
                keys.forEach(d => {
                    if (d !== 'premise_license' && d !== 'practice_license') {
                        form.append(d, userData[d])
                    }
                })
            }
            const [response, error] = await fetchData({
                methods: 'post',
                path: '/api/admin/register-new-user',
                data: form ? form : userData
            })
            setLoading(false);
            if (error) return toast.error(error)
            else {
                toast.success('Successfully Created a new User')
                navigation.push('/dashboard/customers/' + response.data.id)
            }
        } catch (e) {
            toast.error("An error occured")
        }
    }

    // turns it to formData
    const addImages = (e: File, type: string) => {
        const formData = new FormData()
        const keys = Object.keys(userData)
        keys.forEach(d => {
            if (d !== 'premise_license' && d !== 'practice_license') {
                formData.append(d, userData[d])
            }
        })
        formData.append(type, e)
        setformData(formData)
    }



    return (
        <div id="edit_biodata">
            {confirmUpdate && <AlertModals
                message={"User will be created"}
                action={() => createNewUser()}
                close={() => setConfirmUpdate(false)}
            />}
            <section className="py-8" >
                <p className="text-xl font-thin text-nav text-center"> Create New Customer </p>
            </section>
            <section className="bottom-1">
                <div className="md:py-6 md:px-5 py-3 px-4">
                    <div className="md:flex mt-10 gap-8">
                        <div className="md:w-1/2 w-full ">
                            <ModalInput
                                type={"text"}
                                value={userData.first_name}
                                getValue={(e) => setUserData({
                                    ...userData,
                                    first_name: e,
                                })}
                                otherLabel={<i>(Customer / Owner’s Full Name) </i>}
                                label={"Full Name"}
                            />
                            {
                                validationObject.first_name &&
                                <span className="text-red-500 text-xs mt-2"> required </span>
                            }
                        </div>
                        <div className="md:w-1/2 w-full ">
                            <ModalInput
                                type={"text"}
                                value={userData.name}
                                getValue={(e) => setUserData({ ...userData, name: e })}
                                label={"Name of Firm"}
                            />
                            {validationObject.name && <p className="text-red-500 text-xs mt-2"> required </p>}

                        </div>
                    </div>
                    <div className="md:flex  mt-6 gap-8">
                        <div className="md:w-1/3 w-full ">

                            <ModalInput
                                type={"text"}
                                value={userData.email}
                                getValue={(e) => setUserData({ ...userData, email: e })}
                                label={"Email"}
                            />
                            {validationObject.email && <p className="text-red-500 text-xs mt-2"> required </p>}
                        </div>
                        <div className="md:w-1/3 w-full">
                            <ModalInput
                                type={"text"}
                                value={userData.phone_no}
                                getValue={(e) => setUserData({ ...userData, phone_no: e })}
                                label={"Phone Number"}

                            />
                            {validationObject.phone_no && <p className="text-red-500 text-xs mt-2"> required </p>}

                        </div>
                        <div className="md:w-1/3 w-full ">

                            <Select label={"Category"}
                                class={`w-full h-10 px-4 font-light text-left border rounded outline-none text-nav text-sm border-select`}
                                options={category}
                                handleSelect={(e) => setUserData({ ...userData, category: e })}
                            />
                            {validationObject.category && <p className="text-red-500 text-xs mt-2"> required </p>}
                        </div>
                    </div>
                    <div className='mt-6 '>
                        <p className='font-light'>Address <i>(Primary Address)</i></p>
                    </div>
                    <div className="md:flex gap-8">
                        <div className="md:w-1/3 w-full ">
                            <Select
                                class={`w-full px-4 py-3 text-left border rounded outline-none font-light text-nav text-sm border-select`}
                                placeholder='State'
                                options={location}
                                handleSelect={(e) => setUserData({ ...userData, location: e })}
                            />
                            {validationObject.category && <p className="text-red-500 text-xs mt-2"> required </p>}
                        </div>
                        <div className="md:w-1/3 w-full ">
                            <Select
                                placeholder={'LGA'}
                                class={`w-full px-4 py-3 text-left border rounded outline-none text-sm font-light text-nav border-select`}
                                options={localGovernmentAreas}
                            />
                            {validationObject.category && <p className="text-red-500 text-xs mt-2"> required </p>}
                        </div>
                        <div className="md:w-1/3 w-full">

                            <ModalInput
                                type={"text"}
                                value={userData.address}
                                getValue={(e) => setUserData({ ...userData, address: e })}
                                placeholder={"Address"}
                            />

                            {
                                validationObject.address
                                &&
                                <p className="text-red-500 text-xs mt-2">
                                    required
                                </p>
                            }
                        </div>
                    </div>
                    <div className='text-right text-xs text-d_stock_mid_blue mt-3'>
                        <IoMdAddCircle fill='#4B70D6' className='inline' /> Add Branches
                    </div>

                    <div className='mt-6 mb-6text-modal_label text-base'> Login Details </div>

                    <div className="md:flex  md:gap-8">
                        <div className="md:w-1/2 w-full relative">

                            <ModalInput
                                type={showPassword ? "text" : "password"}
                                value={userData.password}
                                getValue={(e) => setUserData({ ...userData, password: e })}
                                label={"Password"}
                            />

                            {
                                validator.isEmpty(userData.password)
                                &&
                                <p className="text-red-500 text-xs mt-2">
                                    required
                                </p>
                            }

                            <div className='absolute' style={{ top: '53px', right: '14px' }}>
                                {showPassword ?
                                    <MdVisibilityOff onClick={(e) => setShowingPassword(!showPassword)} />
                                    :
                                    <MdVisibility onClick={(e) => setShowingPassword(!showPassword)} />
                                }
                            </div>

                        </div>

                        <div className="md:w-1/2 w-full ">
                            <ModalInput
                                type={"text"}
                                value={userData.email}
                                getValue={(e) => setUserData({ ...userData, email: e })}
                                label={"Email"}

                            />
                            {validationObject.email && <p className="text-red-500 text-xs mt-2"> required </p>}
                        </div>

                    </div>

                    <div className="md:flex gap-6 mt-11">

                        <div className="md:w-1/2 w-full">
                            <div className="flex justify-between items-center">
                                <p className='text-base text-license mb-4'>Practice License</p>
                                <div>
                                    <MdInfoOutline />
                                </div>
                            </div>
                            <FileInput
                                fileLabel={userData.practice_license ? "Re-upload Practice License" : 'Upload Practice License'}
                                changeHandler={(file) => addImages(file, 'practice_license')}
                            />
                        </div>


                        <div className="md:w-1/2 w-full mt-5 md:m-0">
                            <div className="flex justify-between items-center">
                                <p className='text-base text-license mb-4'>Premise License</p>
                                <input type={"file"} ref={practice_license} name={"practice_license"} onChange={() => uploadFile("practice_license")} className={"hidden"} />
                                <div>
                                    <MdInfoOutline />
                                </div>
                            </div>

                            <FileInput
                                fileLabel={userData.premise_license ? "Re-upload Premise License" : 'Upload Premise License'}
                                changeHandler={(file) => addImages(file, 'premise_license')}
                            />
                        </div>
                    </div>

                    <div className="md:w-1/2 w-full mt-10">
                        <Select label={"How did you discover drugstoc? (optional)"}
                            class={`w-full h-10 px-4 text-left border rounded outline-none  text-sm font-light text-nav border-select capitalize`}
                            placeholder='e.g. Instagram'
                            options={discover}
                            handleSelect={(e) => setUserData({ ...userData, discover: e })}
                        />
                        {validationObject.discover && <p className="text-red-500 text-xs mt-2"> required </p>}
                    </div>

                    <div className="text-right flex space-x-6 md:justify-end justify-between md:max-w-max ml-auto">
                        <DrugStocRoundButton
                            title="Cancle"
                            class={"bg-transparent border-modal_label border text-white text-sm md:w-auto justify-center mt-5 md:ml-auto  m-0  max-w-max"}
                            paddingY='py-2'
                            paddingX='px-9'
                            textColor={'#484848'}
                            action={() => setConfirmUpdate(true)}

                            loading={loading}
                        />
                        <DrugStocRoundButton
                            title="Create User"
                            class={"bg-d_stock_card_blue text-white text-sm md:w-auto justify-center mt-5 md:ml-auto  m-0  max-w-max"}
                            action={() => setConfirmUpdate(true)}
                            textColor={"white"}
                            paddingY='py-2'
                            paddingX='px-9'
                            loading={loading}
                        />
                    </div>
                </div>
            </section>
        </div>
    )
}
