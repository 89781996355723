import React, { useState } from 'react'
import { Danger } from 'react-iconly';

export default function AlertModals(props: { close: () => void, action: () => void, message: string }) {

    const [loading, setLoading] = useState(false);

    const continueAction = async () => {
        setLoading(true)
        await props.action()
        // setSuccess(true)
        props.close()
        setLoading(false)
    }
    return (
        <div
            className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
            id="my-modal"
        >

            <div
                className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
            >
                <div className="mt-3 text-center">
                    <div
                        className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full`}
                    >
                        <Danger set={"bold"} primaryColor="black" />
                    </div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Warning!</h3>
                    <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500">
                            {props.message}
                        </p>
                    </div>
                    <div className="items-center px-4 py-3 flex justify-between">
                        <button
                            id="ok-btn"
                            className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md  shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300 w-1/3"
                            onClick={props.close}
                            disabled={loading}
                        >
                            Cancle
                        </button>
                        <button
                            id="ok-btn"
                            className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md  shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 w-1/3"
                            onClick={() => continueAction()}
                            disabled={loading}
                        >
                            OK
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}
