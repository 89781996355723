import React, { useEffect, useState } from 'react'
import { Table as TableComponent } from "./ui-components/table";
import { FilterByOption, searchFields, SortByOption, Tabletitle } from '../pages/customers/data';
import moment from 'moment';
import Dropdown from './ui-components/dropdown';
import Skeleton from 'react-loading-skeleton';
import { UserTable, userTableDTO } from '../enums/user.d';
import { useHistory } from 'react-router-dom';
import Fetchhook from '../custom-hooks/fetchhook';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FiFilter } from 'react-icons/fi';


export default function NewCustomers({ moreAction, currentTable, setCurrentTable, exportData }: any): JSX.Element {

    let [duplicateData, setDuplicateData] = useState<any[]>([]);
    let [data, setData] = useState<UserTable[]>([])
    let [loading, setLoading] = useState<boolean>(false)
    const [max, setMax] = useState(1);
    const [next, setNext] = useState(false)
    const [fetchData] = Fetchhook()
    const [page, setPage] = useState(1)
    const [newData, setNewData] = useState(false);
    const location = useHistory()
    const [term, setSearchTerm] = useState('');
    const [filterTerm, setFilterby] = useState('')
    const [orderTerm, setOrderBy] = useState('')
    const [searchBy, setSearchByTerm] = useState('');



    const getData = async (index?: number) => {
        console.log(searchBy);

        if (data.length < max) {
            if (page === 1 || index) setLoading(true)
            const [response, errors] = await fetchData({
                methods: 'get',
                path: '/api/admin/new-users?page=' + page + '&ordering=' + orderTerm + '&' + searchBy + '=' + term,
            })

            if (errors) {
                setNext(false)
                return
            } //eturn toast.error(errors){}
            const newdata = userTableDTO(response.results)
            setMax(response.count)
            if (page === 1 || index) {
                setDuplicateData(newdata)
                setData(newdata)
                setPage(page + 1)
            } else {
                setPage(page + 1)
                setDuplicateData(data.concat(newdata))
                setData(data.concat(newdata))
            }
            if (filterTerm.length) {
                filter(filterTerm)
            }
            setLoading(false)
            setNewData(false)
        } else {
            setNext(false)
        }
    }
    const viewUser = async (id: string | number) => {
        location.push('/dashboard/customers/' + id);
    }

    useEffect(() => {
        setNext(true)
        setMax(1)
        setData([])
        setNewData(false)
        setLoading(true)
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (newData) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newData])

    useEffect(() => {
        if (currentTable === 'enrolments') {
            document.querySelector('.main-dashboard-section')!.addEventListener('scroll', async ({ target }: any) => {
                if (target.scrollHeight - target.scrollTop === target.clientHeight) {
                    if (!newData) {
                        setNewData(true)
                    }
                }
            })
        }
    })


    const filter = (value: string) => {
        setFilterby(value)
        setData(() => duplicateData)
        if (value === '') {
            return
        }
        const d = duplicateData.filter((e) => {
            if (value.toLowerCase() === 'active') {
                return e.status === "active"
            } else if (value.toLowerCase() === 'inactive') {
                return e.status === "inactive"
            } else {
                return false
            }
        })
        setData(d)
    }

    const sort = (value: string) => {
        setOrderBy(() => {
            return value
        })
        getData(1)
        setPage(1)
    }



    const searchData = async (t: string) => {
        if (term.length < 1) return

        setSearchByTerm(t)
        setLoading(true)

        const [response, errors] = await fetchData({
            methods: 'get',
            path: '/api/admin/all-users?page=' + 1 + '&ordering=' + orderTerm + '&' + t + '=' + term,
        })

        setLoading(false)

        if (errors) {
            setNext(false)
            return
        }

        const newdata = userTableDTO(response.results)

        if (newdata.length < 50) {
            setNext(false)
        }

        setMax(response.count)
        setDuplicateData(newdata)
        setData(newdata)
        setPage(1)
        setLoading(false)
    }

    return (
        <>
            <div className="flex w-full justify-between items-center my-11">
                <div className="flex items-center">
                    <button
                        className="border border-transparent pb-1.5 cursor-pointer md:text-base text-xs"
                        style={{ borderColor: `${currentTable === 'customers' ? '#1473E6' : 'transparent'} ` }}
                        onClick={() => { setCurrentTable("customers") }} >
                        All Customers
                    </button>
                    {/* Another component or just switch data */}
                    <button
                        className="border-b-2 border-transparent pb-1.5 md:ml-12 ml-3 cursor-pointer lg:text-base text-sm"
                        style={{ borderColor: `${currentTable === 'enrolments' ? '#1473E6' : 'transparent'} ` }}
                        onClick={() => { setCurrentTable("enrolments") }}>
                        New Enrolments
                    </button>
                </div>


                <div className="flex items-center justify-between mt-3 md:mt-0">
                    <div className="mx-2 flex">
                        <div className="relative text-gray-700 mx-2 ">
                            <input
                                className="lg:h-10 w-72 h-8 pl-5 pr-12 py-2 outline-none placeholder-gray-600 bg-white rounded-full border focus:shadow-outline text-sm"
                                type="text"
                                placeholder="Search for customer"
                                onChange={({ target }) => setSearchTerm(target.value)}
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center px-2 z-10 cursor-pointer">
                                <div>
                                    <Dropdown
                                        label={
                                            <img src='/Arrow.svg' alt='' />
                                        }
                                        options={searchFields}
                                        handleSelect={(t: any) => searchData(t)}
                                        class={"px-2 py-0.5 text-purple-800 text-xs md:text-base ml-3 bg-blue-600 rounded-full h-8 w-8"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex'>
                        <Dropdown
                            label={
                                <span className="flex lg:text-base text-sm items-center">
                                    Filter by
                                    <FiFilter className="ml-3 h-5 hidden lg:block " />
                                    <IoMdArrowDropdown className='ml-2' />
                                </span>
                            }
                            options={FilterByOption}
                            handleSelect={(t: any) => { filter(t) }}
                            class={"px-2 py-0.5 text-purple-800 rounded md:mx-3 mx-1 text-xs md:text-base"}
                        />

                        <Dropdown label={
                            <span className="flex lg:text-base text-sm items-center">
                                Sort by
                                <img src="/filter-sort.svg" alt="dropdown" className="ml-3 h-5 hidden lg:block" />
                                <IoMdArrowDropdown className='ml-2' />
                            </span>}
                            options={SortByOption}
                            handleSelect={(t: string) => sort(t)}
                            class={"px-2 py-0.5 text-purple-800 text-xs md:text-base"}
                        />

                        <button
                            className="md:flex items-center hidden px-1.5 py-0.5 mx-1.5 rounded cursor-pointer md:text-base text-sm"
                            onClick={exportData}>
                            <span>
                                Export
                            </span>
                            <img src="/export.svg" alt='export' className="ml-3" />
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <table className="w-full md_table flex text-xs md:text-base table-customer">
                    <thead className="bg-d_stock_light_blue md:bg-none p-2 md:p-0">
                        <TableComponent title={Tabletitle} showSelelect={true} />
                    </thead>
                    <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs md:text-base">
                        {!loading ?
                            data.map((f: any) => {
                                return (
                                    <tr className="table-body text-base border md:border-none p-2 cursor-pointer hover:bg-gray-100" key={f.email}>
                                        <th className="md:py-6 py-4 text-left align-top block md_table text-xs md:text-base" onClick={() => viewUser(f.id)}>
                                            <div>
                                                <h3 className="md:text-base text-xs font-bold"> {f.name} </h3>
                                                <p className="text-xxs">{f.date}</p>
                                            </div>
                                        </th>
                                        <th className="md:py-6 py-4 text-left align-top  block md_table text-xs md:text-sm" onClick={() => viewUser(f.id)}>
                                            {f.email}
                                        </th>
                                        <th className="md:py-6 py-4 text-left align-top  font-bold block md_table text-xs md:text-sm" onClick={() => viewUser(f.id)}>
                                            {f.phone}
                                        </th>
                                        <th className="md:py-6 py-4 text-left align-top  font-bold block md_table text-xs md:text-sm" onClick={() => viewUser(f.id)}>
                                            {f.last_purchased_date ? moment(f.last_purchased_date).format("LL") : '-'}
                                        </th>
                                        <th className="md:py-6 py-4 text-left align-top  block md_table text-xs md:text-sm" onClick={() => viewUser(f.id)}>
                                            <button className={` 
                                        bg-opacity-10 px-3 py-1 rounded md:text-sm text-xs capitalize
                                            ${f.status === 'active' ?
                                                    'bg-d_stock_fade_green text-d_stock_green' :
                                                    'text-d_stock_red_500 bg-d_stock_red '
                                                }`
                                            }>
                                                {f.status}
                                            </button>
                                        </th>
                                    </tr>
                                )
                            })
                            :
                            // loader
                            <tr>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={4} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={4} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={4} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={4} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={4} /></td>
                            </tr>
                        }
                    </tbody>
                </table>
                {next && (page !== 1 && data.length > 49) && <p className="text-center"> Loading ...</p>}

                {data.length === 0 && !loading && <p className="text-center mt-20 "> No data found</p>}

            </div>
        </>
    )
}
