import axios from 'axios';


let baseUrl = process.env.BASE_URL || 'https://shop.drugstoc.com'

const telimedicineBaseUrl = process.env.API_URL || 'https://shop.drugstoc.com/api'

type FetchHook = {
    methods: 'post' | 'get' | 'delete' | 'patch'
    path: string
    data: any
}




export default function Fetchhook(): any[] {
    const FetchData = async (props: FetchHook) => {
        const token = localStorage.getItem('token')
        console.log(token)
        if (props.path.includes('telimedicine')) {
            baseUrl = telimedicineBaseUrl
        }

        let response: any, errors, path = baseUrl + props.path;
        try {

            let auth = token ? {
                headers: {
                    'Authorization': 'Token ' + token,
                    "Content-Type": props.data instanceof FormData ? "multipart/form-data" : "application/json",
                }
            } : {}

            if (['get', 'delete'].includes(props.methods) ) {
                response = await axios[props.methods as 'get' | 'delete'](path, auth)
            } else {
                response = await axios[props.methods](path,
                    props.data,
                    auth
                )
            }
            console.log(auth)
        } catch (err: any) {
            console.log(err)
            // console.log(err.response)
            errors = err.response ? err.response.data.message ? err.response.data.message : err.response.data.detail : "An Error Occured!!."
            if (errors) {
                if (errors.includes('Invalid token')) localStorage.clear()
            } else {
                errors = "An Error occured"
            }

        }
        return [response ? response.data : '', errors]
    }
    return [FetchData]
}
