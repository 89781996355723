import { Transition } from '@headlessui/react';
import { ArrowRight } from 'react-iconly';
import { useDispatch, useSelector } from 'react-redux';
import { responsiveActionType } from '../redux/responsive/action';
import { MdSearch, MdNotificationsNone } from "react-icons/md";
import { FiMessageSquare } from "react-icons/fi";
import Dropdown from './ui-components/dropdown';
import { Link } from 'react-router-dom';


export default function Topnav(props: { name: string }) {
    const dispatch = useDispatch()
    const show = useSelector((state: any) => state.responsive.show)
    return (
        <div className="flex items-center justify-between w-full flex-row-reverse lg:flex-row">
            <section className="flex w-2/3">
                <div className="hidden lg:block w-full">
                    <div className="relative text-gray-700 mx-2">
                        <input className="w-full lg:h-10 h-8 pl-16 pr-3 py-2 outline-none placeholder-search text-search bg-white rounded-full focus:shadow-outline text-xs" type="text" placeholder="Search for everything" />
                        <div className="absolute left-10" style={{ top: '15px' }}>
                            <MdSearch className='text-search text-sm' />
                        </div>
                        <div className="absolute h-7 w-7 m-1 right-0 flex items-center px-2 pointer-events-none bg-blue-600 rounded-full" style={{ top: '2px' }}>
                            <ArrowRight set="light" primaryColor="white" size="small" />
                        </div>
                    </div>
                </div>
                <Transition show={!show} appear={true}>
                    <button className="hamburger block lg:hidden" onClick={() => dispatch({
                        type: responsiveActionType.TOGGLE_NAV,
                        payload: true
                    })}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </button>
                </Transition>
                <Transition show={show} appear={true}>
                    <span className="hamburger close block lg:hidden text-4xl" style={{ lineHeight: "19px", fontSize: "44px" }} onClick={() => dispatch({
                        type: responsiveActionType.TOGGLE_NAV,
                        payload: true
                    })}>
                        ×
                    </span>
                </Transition>
            </section>
            <div className="flex space-x-4 items-center">
                <div className=" ">


                    <Dropdown
                        label={
                            <button className="md:h-10 md:w-10 h-6 w-6  flex items-center justify-center bg-transparent" >
                                <MdNotificationsNone className='font-thin' size={'25px'} fill='#61656A' />
                            </button>
                        }
                        align={"left"}
                        hover={false}
                        activeClass={false}
                        options={
                            [
                                {
                                    label: <div>
                                        <h1 className='text-xl mb-1.5'> Notifications </h1>
                                        <p className='text-xs'> You have 10 unread notifications</p>
                                        <hr className='mt-1.5' />
                                    </div>,
                                    value: "1"
                                },
                                {
                                    label: 'One Order, just Now',
                                    value: "1"
                                },
                                {
                                    label: 'One Order, just Now',
                                    value: "2"
                                },
                                {
                                    label: 'One Order, just Now',
                                    value: "3"
                                },
                                {
                                    label: <div className='mt-5 pb-1.5 text-center hover:bg-transparent'>
                                        <hr className='mb-1.5' />
                                        <Link to="#" className='text-d_stock_mid_blue'> View All </Link>
                                    </div>,
                                    value: "4"
                                },
                            ]
                        }
                    />
                </div>
                <div className="">
                    <button className="md:h-10 md:w-10 h-6 w-6  flex items-center justify-center bg-transparent relative" >
                        <FiMessageSquare className='text-license font-thin' size={'25px'} />
                        <div className='absolute w-2 h-2 bg-d_stock_mid_blue rounded-full' style={{ top: '7px', right: '7px' }}></div>
                    </button>
                </div>
            </div>
        </div>
    )
}