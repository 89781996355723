import React, { useEffect, useState } from 'react'
import { Table as TableComponent } from "./ui-components/table";
import { FilterByOption, SortByOption, Tabletitle } from '../pages/customers/data';
import moment from 'moment';
import Dropdown from './ui-components/dropdown';
import Skeleton from 'react-loading-skeleton';
import { UserTable, userTableDTO } from "../enums/user.d"
import { Link, useHistory } from 'react-router-dom';
import Fetchhook from '../custom-hooks/fetchhook';
import { FiFilter } from 'react-icons/fi'
import { IoMdArrowDropdown } from 'react-icons/io'
import { RiArrowDropRightLine } from "react-icons/ri";
import { AiOutlineUserAdd } from 'react-icons/ai';

import { RangePicker } from "react-trip-date";
import Modal from "../extra-components/modals";
import DrugStocRoundButton from "./ui-components/button-round";

export default function CustomersTables({ moreAction, currentTable, setCurrentTable, exportData, showFormModal, setItemsInCartModal }: any): JSX.Element {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [_duplicateData, setDuplicateData] = useState<any[]>([]);
    let [data, setData] = useState<UserTable[]>([])
    let [loading, setLoading] = useState<boolean>(false)
    const [max, setMax] = useState(1);
    const [next, setNext] = useState(false)
    const [fetchData] = Fetchhook()
    const [page, setPage] = useState(1)
    const [, setNewData] = useState(false);
    const location = useHistory();
    const [term, setSearchTerm] = useState('');
    const [filterTerm, setFilterby] = useState('')
    const [orderTerm, setOrderBy] = useState('')
    const [showDatePicker, setShowDatePicker] = useState(false)



    const getData = async (index?: number) => {
        if (data.length < max) {
            if (page === 1 && index === undefined) setLoading(true)
            const [response, errors] = await fetchData({
                methods: 'get',
                path: '/api/admin/all-users?page=' + (index ? index : page) + '&ordering=' + orderTerm + '&search=' + term + '&is_verified=' + filterTerm,
            })
            if (errors) {
                return false
            } //eturn toast.error(errors){}
            const newdata = userTableDTO(response.results)
            setMax(response.count)
            if (page === 1 && index === undefined) {
                setData(newdata)
                setPage(page + 1)
            } else {
                setData([...data, ...newdata])
                setPage(index as number)
            }
            setLoading(false)
            setNewData(false)
            setNext(true)
        } else {
            return false
        }
        return true
    }
    const viewUser = async (id: string | number) => {
        location.push('/dashboard/customers/' + id);
    }

    useEffect(() => {
        console.log('again');

        function handleScroll({ target }: any) {
            if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
                if (currentpage === 1) {
                    dontLoad = true
                    getData(currentpage += 1).then(e => {
                        if (e) dontLoad = false
                    })
                } else if (!dontLoad) {
                    dontLoad = true
                    getData(currentpage += 1).then(e => {
                        if (e) dontLoad = false
                    })
                }
            }
        }

        setPage(1)
        setNext(true)
        setMax(1)
        setData([])
        setNewData(false)
        setLoading(true)
        if (!orderTerm.length && !term.length && !filterTerm.length) {
            getData(1)
        }
        let dontLoad = false
        let currentpage = 1

        document.querySelector('.main-dashboard-section')!.addEventListener('scroll', handleScroll, false)

        return () => document.querySelector('.main-dashboard-section')!.removeEventListener('scroll', handleScroll, false);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderTerm, term, filterTerm])

    const searchData = async () => {
        setData([])
        if (term.length < 1) return
        setLoading(true)

        const [response, errors] = await fetchData({
            methods: 'get',
            path: '/api/admin/all-users?page=' + 1 + '&ordering=' + orderTerm + '&search=' + term + '&is_verified=' + filterTerm,
        })
        setLoading(false)
        if (errors) {
            setNext(false)
            return
        }
        const newdata = userTableDTO(response.results)
        if (newdata.length < 50) {
            setNext(false)
        }
        setMax(response.count)
        setDuplicateData(newdata)
        setData(newdata)
        setPage(1)
        setLoading(false)
    }

    const filter = async (value: string) => {
        setFilterby(value)
        setLoading(true)
        setData([])
        const [response, errors] = await fetchData({
            methods: 'get',
            path: '/api/admin/all-users?page=' + 1 + '&ordering=' + orderTerm + '&search=' + term + '&is_verified=' + value,
        })
        setLoading(false)
        if (errors) {
            setNext(false)
            return
        }
        const newdata = userTableDTO(response.results)
        if (newdata.length < 50) {
            setNext(false)
        }
        setMax(response.count)
        setDuplicateData(newdata)
        setData(newdata)
        setPage(1)
        setLoading(false)
    }

    const sort = async (key: string, value: string) => {
        setData([])
        setOrderBy(value)
        if (value.length < 1) return
        setLoading(true)
        const [response, errors] = await fetchData({
            methods: 'get',
            path: '/api/admin/all-users?page=' + 1 + '&ordering=' + value + '&search=' + term + '&is_verified=' + filterTerm,
        })
        setLoading(false)
        if (errors) {
            setNext(false)
            return
        }
        const newdata = userTableDTO(response.results)
        if (newdata.length < 50) {
            setNext(false)
        }
        setMax(response.count)
        setDuplicateData(newdata)
        setData(newdata)
        setPage(1)
        setLoading(false)
    }

    return (
        <>
            <div className="flex space-x-2 items-center">
                <Link to="/dashboard/overview" className='text-xs' style={{ color: '#52575e' }}> Dashboard</Link>
                <RiArrowDropRightLine style={{ color: '#52575e' }} />
                <Link to="/dashboard/customers" className='text-xs text-d_stock_mid_blue'>Customers</Link>
            </div>
            <div className="md:flex block w-full bg-white py-6 px-10 rounded justify-between items-center my-11">
                <div className="flex items-center">
                    <button
                        className="border rounded-full border-transparent py-2 px-4 text-d_stock_mid_blue cursor-pointer md:text-sm text-xs"
                        style={{ borderColor: `${currentTable === 'customers' ? '#1473E6' : 'transparent'} ` }}
                        onClick={() => showFormModal()} >
                        <AiOutlineUserAdd fill="#4B70D6" size="20" className='inline' /> Add Customers
                    </button>
                </div>
                <div className="relative text-gray-700 mx-2 w-2/5">
                    <input
                        className="lg:h-10 w-full h-8 pl-5 pr-12 py-2 outline-none placeholder-holder bg-white rounded-full border focus:shadow-outline text-sm"
                        type="text"
                        placeholder="Search for customer"
                        onChange={({ target }) => setSearchTerm(target.value)}
                    />
                    <div className="absolute top-0 bottom-1 right-0 flex items-center px-2 z-10 cursor-pointer">
                        <div>
                            <button
                                onClick={() => searchData()}
                                className={"px-2 py-0.5 text-purple-800 text-xs md:text-base ml-3 bg-blue-600 rounded-full h-6 w-6"}
                            >
                                <img src='/Arrow.svg' alt='' />

                            </button>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <Dropdown
                    label={
                        <span className="flex lg:text-base text-license text-sm items-center">
                            Filter by
                            <FiFilter className="ml-3 h-5 hidden lg:block " />
                            <IoMdArrowDropdown className='ml-2 text-license' />
                        </span>
                    }
                    options={FilterByOption}
                    handleSelect={(t: any) => { filter(t) }}
                    class={"px-2 py-0.5 text-purple-800 rounded md:mx-3 mx-1 text-sm"}
                />


                {showDatePicker && <Modal close={() => setShowDatePicker(false)}>  <div className={"bg-white"}>
                    <RangePicker
                        onChange={(e) => console.log(e)}
                    />
                    <div className={"text-center flex items-center justify-center p-3"}>
                        <DrugStocRoundButton title={"filter"} action={() => setShowDatePicker(false)} class={"text-center border"} />
                    </div>
                </div>
                </Modal>}

                <Dropdown label={
                    <span className="flex  text-sm items-center text-license">
                        Sort by
                        <img src="/filter-sort.svg" alt="dropdown" className="ml-3 h-5 hidden lg:block" />
                        <IoMdArrowDropdown className='ml-2 text-license text-xs' />
                    </span>
                }
                    options={SortByOption}
                    handleSelect={(t: string) => sort('ordering', t)}
                    class={"px-2 py-0.5 text-purple-800 text-xs md:text-base"}
                />

                <button
                    className="md:flex items-center hidden px-1.5 py-0.5 mx-1.5 rounded cursor-pointer  text-sm text-license"
                    onClick={exportData}>
                    <span>
                        Export
                    </span>
                    <img src="/export.svg" alt='export' className="ml-3" />
                </button>
                {/* </div> */}
            </div>
            <div className="table-holder">
                <table className="w-full md_table flex text-xs md:text-base table-customer bg-white">
                    <thead className="bg-header md:bg-none p-2 md:p-0">
                        <TableComponent title={Tabletitle} showSelelect={true} />
                    </thead>
                    <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs md:text-base">
                        {!loading ?
                            data.map((f: any) => {
                                return (
                                    <tr className=" border md:border-none p-2 cursor-pointer text-label hover:bg-header hover:text-d_stock_mid_blue" key={f.email}>
                                        <td className="md:py-6 py-2 px-4  text-left  align-top block md_table text-xs md:text-sm" onClick={() => viewUser(f.id)}>
                                            {f.name}
                                        </td>
                                        <td className="md:py-6 py-2 px-3 text-left  align-top   block md_table text-xs md:text-sm" onClick={() => viewUser(f.id)}>
                                            {f.phone}
                                        </td>
                                        <td className="md:py-6 py-2 px-3 text-left  align-top   block md_table text-xs md:text-sm" onClick={() => viewUser(f.id)}>
                                            7 days ago
                                        </td>
                                        <td className="md:py-6 py-2 text-left  align-top px-3  block md_table text-xs md:text-sm" onClick={() => viewUser(f.id)}>
                                            {f.last_purchased_date ? moment(f.last_purchased_date).fromNow() : '-'}
                                        </td>
                                        <td className="md:py-6 py-2 px-3 text-left  align-top   block md_table text-xs md:text-sm" onClick={() => setItemsInCartModal(true)}>
                                            2
                                        </td>
                                        <td className="md:py-6 py-2 text-left align-top   block md_table text-xs md:text-sm" onClick={() => viewUser(f.id)}>
                                            <button className={` w-28 px-3 py-2 rounded-full md:text-sm text-xs lowercase
                                                ${f.status === 'active' ?
                                                    'bg-d_stock_fade_green text-d_stock_green bg-opacity-60' :
                                                    'text-d_stock_red_500 bg-d_stock_red  bg-opacity-30'
                                                }`
                                            }>
                                                {f.status}
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            // loader
                            <tr>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            </tr>
                        }
                    </tbody>
                </table>

                {
                    next &&
                    <p className="text-center text-label text-sm"> Loading ...</p>
                }
                {
                    data.length === 0 && !loading &&
                    <p className="text-center mt-20 "> No data found</p>
                }

            </div>
        </>
    )
}
