import React, { useEffect } from 'react'
import { Line } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';
import { externalTooltipHandler } from "../../utils/chart.config"

const options = {
    maintainAspectRatio: false,
    plugins: {
        tooltip: {
            enabled: false,
            position: "nearest",
            external: externalTooltipHandler,
            callbacks: {
                label: function (x: any) {
                    return '₦' + new Intl.NumberFormat("en").format(x.raw * 1000000);
                },
            }

        },
        legend: { display: false },
        title: {
            display: false,
            text: "Sales chart showing sales in millions",
            position: "top"
        },
    },
    scales: {
        "xAxes": {
            display: true,
            grid: {
                display: false,
            },
            stacked: true,
            scaleLabel: {
                display: true,
                labelString: 'probability'
            }
        },
        "yAxes": {
            grid: {
                drawBorder: false,
            },
            ticks: {
                stepSize: 20
            },
            // stacked:true,
            scaleLabel: {
                display: true,
                labelString: 'probability'
            }
        },
    },
}


const VerticalBar = (props: any) => {

    console.log(props.data?.datasets?.[0].data)

    const data = (canvas: { getContext: (arg0: string) => any; }) => {
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 500);
        gradient.addColorStop(0, 'rgba(97, 135, 240, 0.193)');
        gradient.addColorStop(1, 'white');

        return {
            labels: ['01/12', '02/12', '03/12', '04/12', '05/12', '06/12', '07/12', '08/12', '09/12', '10/12', '11/12', '12/12'],
            datasets: props.data?.datasets?.[0].data
        }
    }



    useEffect(() => {
        let container = document.querySelector('.main-dashboard-section')
        if (container) {
            container!.addEventListener('scroll', e => {
                let tooltip = document.querySelector('.tool_tip')
                if (document.contains(tooltip)) {
                    tooltip!.classList.add('hidden')
                }
            })
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', e => { })
            }
        };
    });

    return (<>
        <div className="vertical_bar">
            {/*@ts-ignore*/}
            {props.data.datasets ? <Line data={data} options={{ ...options }} /> : <div className="">
                <p className="text-red-500"> {props.errors}  </p>
                <Skeleton height={"450px"} />
            </div>}
            {/*@ts-ignore*/}
            {/* {<Bar data={data} options={{ ...options }} />} */}
        </div>
    </>)
};


export default VerticalBar;
