import React, {useRef, useState} from 'react'
// import { PaperUpload } from 'react-iconly'

type fileProps = {
    fileLabel?: string
    changeHandler: (file: any) => void
}

export default function FileInput(props: fileProps) {

    const input_field = useRef<any>({})
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [file, setFile] = useState({
        name: ''
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const setFileDetails = (e: any) => {
        props.changeHandler(e.target.files[0]);
        setFile({ name: e.target.files[0].name });
    }

    return (
        <div>
            <div className=" w-full flex">
                <input type={"file"} className={"hidden"} ref={input_field} onChange={(e) => setFileDetails(e)}/>
                <div  className="w-3/4 bg-file_two text-sm pl-3 focus:outline-none rounded" style={{ paddingTop: '7px', paddingBottom: '7px' }} id='__remove' placeholder='Choose file' >
                    {file.name}
                </div>
                <button className=" py-2 text-sm px-4 w-1/4" style={{ background: '#DDDDDD' }} type="button" onClick={() => input_field.current.click()}>Choose</button>
            </div>
        </div>
    )
}
