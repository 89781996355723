import React, { useEffect, useState } from 'react';
import { InfoCircle } from 'react-iconly'
import Fetchhook from '../../custom-hooks/fetchhook';
import VerticalBar from './barchart';

export default function SalesChart() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentDuration, setCurrentDuration] = useState("Month")

    const [fetchData] = Fetchhook()
    const [, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [errors, setErrors] = useState(false)

    const getData = async () => {
        setLoading(true)
        const [response, errors] = await fetchData({
            methods: 'get',
            path: '/api/admin/dataset',
        })
        if (errors) setErrors(errors)
        else {
            response.datasets[0].data = response.datasets[0].data.map((e: number) => {
                return e / 1000000
            })
            setData(response)
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <section className=' __order_shadow mx-3 mt-12 p-8 bg-white rounded'>
                <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center ">
                        <span className="mr-2 text-blue-500"> Total Sales  </span>
                        <InfoCircle size={18} filled={false} style={{ color: '#D0D1D2', opacity: 0.6, cursor: 'pointer' }} />
                    </div>
                    <div>
                        <div className="p-0.5 flex items-center gap-5">
                            <p className='text-sm text-gray-600 opacity-50'>
                                Current Month
                            </p>
                            <button className="text-gray-600 p-1.5 h-8 text-base flex items-center gap-2 border rounded cursor-pointer disabled cursor-not-allowed font-light" onClick={() => setCurrentDuration('Year')}>
                                November 2020 <img src="/calendar.svg" alt="calendar" className='inline' />
                            </button>
                            <button className=" h-8 rounded border   p-1.5 flex items-center justify-center"
                            >
                                <img src="/more-horizontal.svg" alt="more" className='incline' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full ">
                    {<VerticalBar data={data} errors={errors} />}
                </div>
            </section>

        </>
    );

}
