import { useEffect, useState } from 'react'
import { Table as TableComponent } from "../../components/ui-components/table";
import Skeleton from 'react-loading-skeleton';
import {useHistory, useLocation} from 'react-router-dom';
import DrugStocRoundButton from '../../components/ui-components/button-round';
import Dropdown from '../../components/ui-components/dropdown';
import Modal from '../../extra-components/modals';
import { FiFilter } from 'react-icons/fi';
import { IoMdArrowDropdown } from 'react-icons/io';
import { RangePicker } from 'react-trip-date';
import { FilterByOption, SortByOption } from '../customers/data';
import Fetchhook from '../../custom-hooks/fetchhook';
import moment from 'moment';
import {toast} from "react-toastify";
import {useQuery} from "../../custom-hooks/query";
import {Analytics} from "./analytics";

type tabprop = {
    tab: string,
    tabName?: string
}
export const TeleHealthTabs = ( props:tabprop ) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, _setLoading] = useState(false);
    const [data, setData] = useState([]);
    const history = useHistory()
    function handleClick(id: string | number) {
        history.push("/dashboard/tele-health/" + id);
    }
    const [selectedAction, setselectedAction] = useState('');
    const [requestError, setError] = useState(false);

    const Tabletitle = [
        "ID",
        "Customer",
        "Order Number",
        "Phone Number",
        "ETA",
        "Created Date",
        "Status"
    ]


    enum Status {
        Pending = "pending",
        Completed = "completed",
        Cancelled = "cancelled"
    }

    const [fetchDAta] = Fetchhook()

    const getData = async () => {
        try {
            _setLoading(true)
            setError(false)
            console.log('response', 1)

            const [response, errors] = await fetchDAta({
                methods: 'get',
                path: '/telimedicine/precription'
            })
            if (errors) setError(true)
            console.log(errors);

            console.log(response, 'response')
            setData(response.results)
        } catch (error) {
            console.log(error)
        }
        _setLoading(false)
    };

    const setCurrentStatus = async (status: Status, index: string | number) => {
        try{
            const [response, errors] = await fetchDAta({
                methods: status === Status.Completed ? 'put' : 'delete',
                path: '/telimedicine/precription/' + index
            })
            if (errors) {
                toast.error(errors.message)
            }
            console.log(response, 'response')
            toast.success('Status Updated Successfully')
            await getData()

        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="mt-8">

            {selectedAction === 'register-payment' && <Modal close={() => setselectedAction('')}>
                <div className='bg-white px-10 pb-6 pt-8 rounded '>
                    <h3 className='text-center text-2xl'>Register Payment</h3>
                    <section className='grid grid-cols-2 gap-7'>
                        <div className="mt-4">
                            <label htmlFor="payment-journal">
                                Payment Journal
                            </label>
                            <br />
                            <input id="payment-journal" className="border rounded h-9 mt-4 p-3" />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="payment-journal">
                                Payment Journal
                            </label>
                            <br />
                            <input id="payment-journal" className="border rounded h-9 mt-4 p-3" type={'date'} />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="payment-journal">
                                Payment Amount
                            </label>
                            <br />
                            <input id="payment-journal" className="border rounded h-9 mt-4 p-3" type={'number'} placeholder='₦ | 4000' />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="payment-journal">
                                Memo
                            </label>
                            <br />
                            <input id="payment-journal" className="border rounded h-9 mt-4 p-3" />
                        </div>
                    </section>
                    <div className='flex gap-5 justify-end mt-5'>
                        <DrugStocRoundButton title="Cancel" class="text-black mt-4 border-2" action={() => setselectedAction('')} />
                        <DrugStocRoundButton title="Validate" class="bg-blue-500 text-white mt-4" />
                    </div>
                </div>
            </Modal>}
            <table className="w-full md_table flex text-xs md:text-base table-customer bg-white">
                <thead className="bg-header md:bg-none p-2 md:p-0">
                    <TableComponent textAlign="text-center" title={Tabletitle} showSelelect={true} />
                </thead>

                <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs md:text-base w-full">
                    {!loading ?
                        data?.map((f: any, i: number) => {
                            return (
                                <tr className=" border-b  cursor-pointer text-license hover:bg-header hover:text-d_stock_mid_blue todoLeftImportance" key={f.account} >
                                    <td className="md:py-4 py-2 px-3 text-center align-top md_table text-sm"  onClick={() => { handleClick(f.id) }}>
                                        {f.id}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-center  align-top block md_table text-sm" onClick={() => { handleClick(f.id) }}>
                                        {f.practinonal_first_name || '-'} {f.practinonal_last_name || '-'}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-center  align-top block md_table text-sm" onClick={() => { handleClick(f.id) }}>
                                        {f.order_number}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" onClick={() => { handleClick(f.id) }}>
                                        {f.practinonal_phone_number || '-'}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" onClick={() => { handleClick(f.id) }}>
                                        {moment(f.estimated_delivery).fromNow()}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" onClick={() => { handleClick(f.id) }}>
                                        {moment(f.created_date).format('MMMM Do YYYY, h:mm:ss a')}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm">
                                        <Dropdown label={
                                            <span className="flex justify-center text-sm items-center text-license z-10">
                                                  {f.status || 'pending'} <img src="/filterdots.svg" alt="dropdown" className="ml-3 h-5 hidden lg:block" />
                                            </span>
                                        }
                                                  options={[
                                                      {
                                                          label: 'Mark Completed',
                                                          value: Status.Completed
                                                      },
                                                      {
                                                          label: 'Delete',
                                                          value: Status.Cancelled
                                                      },
                                                  ]}
                                                  handleSelect={(t: string) => setCurrentStatus(t as Status, f.id)}
                                                  // class={"px-2 py-0.5 text-purple-800 text-xs md:text-base z-10"}
                                        />
                                    </td>
                                </tr>

                            )
                        })
                        :
                        // loader
                        <tr>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                        </tr>
                    }
                </tbody>
            </table>

            {
                data?.length === 0 && <p className='text-center mt-12'>  No Data Found </p>
            }

            {
                requestError && <p className='text-center mt-12'>  Error Occured trying to make this request </p>
            }

        </section>
    )
}


export const TeleHealth = () => {
    enum TABS {
        recent= "RECENT",
        pending = "PENDING",
        completed = "COMPLETED",
        analysis = "ANALYSIS"
    }

    const query = useQuery();
    const location = useLocation();

    useEffect(() => {
        setCurrentTab( query.get('tab') || TABS.recent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, query]);

    const tab = query.get('tab')

    const [currentTab, setCurrentTab] = useState( tab || TABS.recent);

    const RenderCurrentTab = () => {
        switch (currentTab) {
            case TABS.analysis:
                return <Analytics/>
            default:
                return <TeleHealthTabs tab={currentTab} />
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchTerm, setSearchTerm] = useState('');

    const searchData = async () => { }

    const sort = async (sortBy: any, t: string) => { }

    const filter = async (term: string) => { }

    const exportData = async () => { }

    const [showDatePicker, setShowDatePicker] = useState(false);

    const history = useHistory();


    return (

        <>
            <div className="md:flex block w-full bg-white py-6 px-10 rounded justify-between items-center my-11">
                <div className="relative text-gray-700 mx-2 w-2/5">
                    <input
                        className="lg:h-10 w-full h-8 pl-5 pr-12 py-2 outline-none placeholder-holder bg-white rounded-full border focus:shadow-outline text-sm"
                        type="text"
                        placeholder="Search for customer"
                        onChange={({ target }) => setSearchTerm(target.value)}
                    />
                    <div className="absolute top-0 bottom-1 right-0 flex items-center px-2 z-10 cursor-pointer">
                        <div>
                            <button
                                onClick={() => searchData()}
                                className={"px-2 py-0.5 text-purple-800 text-xs md:text-base ml-3 bg-blue-600 rounded-full h-6 w-6"}
                            >
                                <img src='/Arrow.svg' alt='' />

                            </button>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <Dropdown
                    label={
                        <span className="flex lg:text-base text-license text-sm items-center">
                            Filter by
                            <FiFilter className="ml-3 h-5 hidden lg:block " />
                            <IoMdArrowDropdown className='ml-2 text-license' />
                        </span>
                    }
                    options={FilterByOption}
                    handleSelect={(t: any) => { filter(t) }}
                    class={"px-2 py-0.5 text-purple-800 rounded md:mx-3 mx-1 text-sm"}
                />


                {showDatePicker && <Modal close={() => setShowDatePicker(false)}>  <div className={"bg-white"}>
                    <RangePicker
                        onChange={(e) => console.log(e)}
                    />
                    <div className={"text-center flex items-center justify-center p-3"}>
                        <DrugStocRoundButton title={"filter"} action={() => setShowDatePicker(false)} class={"text-center border"} />
                    </div>
                </div>
                </Modal>}

                <Dropdown label={
                    <span className="flex  text-sm items-center text-license">
                        Sort by
                        <img src="/filter-sort.svg" alt="dropdown" className="ml-3 h-5 hidden lg:block" />
                        <IoMdArrowDropdown className='ml-2 text-license text-xs' />
                    </span>
                }
                          options={SortByOption}
                          handleSelect={(t: string) => sort('ordering', t)}
                          class={"px-2 py-0.5 text-purple-800 text-xs md:text-base"}
                />

                <button
                    className="md:flex items-center hidden px-1.5 py-0.5 mx-1.5 rounded cursor-pointer  text-sm text-license"
                    onClick={exportData}>
                    <span>
                        Export
                    </span>
                    <img src="/export.svg" alt='export' className="ml-3" />
                </button>
                {/* </div> */}
            </div>
            <div className="flex justify-start items-center justify-between mb-8 w-full text-gray-600 px-6">
                <div className="space-x-6 flex">
                    <div className={"cursor-pointer"} onClick={() => history.push('?tab=' + TABS.recent) }>
                        Recent
                    </div>
                    <div className={'cursor-pointer relative px-3'} onClick={() => history.push('?tab=' + TABS.pending) }>
                        Pending
                        <div className={"absolute w-4 h-4 rounded-full bg-blue-600 text-white flex justify-center align-center text-xs -top-3 right-0"}>
                            4
                        </div>
                    </div>
                    <div className={'cursor-pointer'} onClick={() => history.push('?tab=' + TABS.completed) }>
                        Confirmed
                    </div>
                </div>
                <div className={"flex gap-2 cursor-pointer"} onClick={() => history.push('?tab=' + TABS.analysis) }>
                    Analysis <img src="/analysis.svg" alt="analysis"  />
                </div>

            </div>

            { RenderCurrentTab() }
        </>

    )
}
