import React from 'react';
import {BsCalendarDate} from "react-icons/all";
import moment from "moment";
import { Line } from 'react-chartjs-2';
// import Skeleton from 'react-loading-skeleton';
import {externalTooltipHandler} from "../../utils/chart.config";



const options = {
        elements:{
            line: {
                tension: 0.3,
            }
        },
        maintainAspectRatio: false,
        plugins: {
                tooltip: {
                        enabled: false,
                        position: "nearest",
                        external: externalTooltipHandler,
                        callbacks: {
                                label: function (x: any) {
                                        return '₦' + new Intl.NumberFormat("en").format(x.raw * 1000000);
                                },
                        }

                },
                legend: { display: false },
                title: {
                        display: false,
                        text: "Sales chart showing sales in millions",
                        position: "top"
                },
        },
        scales: {
                "xAxes": {
                        display: true,
                        grid: {
                                display: false,
                        },
                        stacked: true,
                        scaleLabel: {
                                display: true,
                                labelString: 'probability'
                        }
                },
                "yAxes": {
                        grid: {
                                drawBorder: false,
                        },
                        ticks: {
                                stepSize: 24
                        },
                        // stacked:true,
                        scaleLabel: {
                                display: true,
                                labelString: 'probability'
                        }
                },
        },
}

type miniChartProps = {
        title: string,
        subtitle:string,
        data?: any,
        loading?: boolean,
        width?: number,
        height?: number,
        errors?: string
}

const AnalyticsChart = (props:miniChartProps) => {
        const data = (canvas: { getContext: (arg0: string) => any; }) => {
                const ctx = canvas.getContext("2d");
                const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                gradient.addColorStop(0, 'rgba(97, 135, 240, 0.193)');
                gradient.addColorStop(1, 'white');

                return {
                        labels: ['01/12', '02/12', '03/12', '04/12', '05/12', '12/12'],
                        datasets: [
                                {
                                        label: '',
                                        data: [
                                                Math.random() * 100,
                                                Math.random() * 100,
                                                Math.random() * 100,
                                                Math.random() * 100,
                                                Math.random() * 100,
                                                Math.random() * 100,
                                        ],
                                        backgroundColor: gradient,
                                        borderColor: 'blue',
                                        borderWidth: 4,
                                        pointBorderColor: 'blue',
                                        pointBackgroundColor: 'blue',
                                        pointBorderWidth: 3,
                                        pointHoverRadius: 5,
                                        pointHoverBackgroundColor: 'blue',
                                        pointHoverBorderColor: '#fff',
                                        pointHoverBorderWidth: 2,
                                        pointRadius: 1,
                                        pointHitRadius: 10,
                                        fill: true,
                                },
                        ],
                }
        }

        return  (
            <>
                    <div className={"flex items-center justify-between"}>
                            <div className={"text-lg font-bold"} style={{color: '#4B70D6'}}>
                                    {props.title}
                            </div>
                            <div className={"flex gap-10 items-center"}>
                                    <div>
                                            Current Month
                                    </div>
                                    <button className={"border p-1.5 flex gap-10 items-center justify-center rounded"}>
                                                        <span className="text-sm">
                                                              {moment().format('MMMM YYYY')}
                                                        </span>
                                            <BsCalendarDate/>
                                    </button>
                            </div>
                    </div>
                <div className={"text-2xl mb-10 font-bold"}>
                        {props.subtitle}
                </div>
                <div className="vertical_bar">
                        {/*@ts-ignore*/}
                        {<Line data={data} options={{ ...options }} />
                                //         <div className="">
                                //         <p className="text-red-500"> {props.errors} </p>
                                //         <Skeleton height={"450px"} />
                                // </div>
                        }
                </div>
            </>)
}

export const Analytics = () => {
        return <>
                <section className={"bg-white p-10 shadow-lg rounded-lg"}>
                       <AnalyticsChart title={"Total Revenue Generated"} subtitle={"₦100,000,000"} />
                </section>
                <section className={"bg-white p-10 shadow-lg rounded-lg flex gap-20"}>
                        <div className={"w-2/3 border p-8 rounded-lg"}>
                                <AnalyticsChart title={"Total Revenue Generated"} subtitle={"0.5%"} />
                        </div>
                        <div className={"border p-8 rounded-lg w-1/3"}>
                                <p className={"my-3"}> Number of Registered Vendors </p>
                                <hr/>
                                <div className={"flex justify-between my-3"}>
                                        <div className={"font-bold"}>
                                                2456
                                        </div>
                                        <div>
                                                0.5%
                                        </div>

                                </div>
                        </div>
                </section>

        </>
}

