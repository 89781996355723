import './style.css'

import { RiArrowDropRightLine } from "react-icons/ri"
import ApprovedOrders from "./components/approvedOrders"
import CompletedOrders from "./components/completedOrders"
import Orders from "./components/orders"
import ProcessingOrders from "./components/processingOrders"
import RejectedOrders from "./components/rejectedOrders"
import {Switch, Route, NavLink, Link} from "react-router-dom"
import React from "react";


enum TABS {
    ORDERS = 'ORDERS',
    PROCESSING = 'PROCESSING',
    COMPLETED = 'COMPLETED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}

const Tabs = [
    {
        name: 'Orders',
        component: <Orders />,
        slug: TABS.ORDERS
    },
    {
        name: 'Processing Order',
        component: <ProcessingOrders />,
        slug: TABS.PROCESSING
    },
    {
        name: 'Approved Orders',
        component: <ApprovedOrders />,
        slug: TABS.APPROVED
    },
    {
        name: 'Rejected Orders',
        component: <RejectedOrders />,
        slug: TABS.REJECTED
    },
    {
        name: 'Completed Orders',
        component: <CompletedOrders />,
        slug: TABS.COMPLETED
    }
]


export default function Sales() {
    const url = 'dashboard/sales'
    return (
        <div>

            <div className="flex space-x-2 items-center mt-10">
                <Link to="/dashboard/overview" className='text-xs' style={{ color: '#52575e' }}> Dashboard</Link>
                <RiArrowDropRightLine style={{ color: '#52575e' }} />
                <Link to="/dashboard/sales" className='text-xs text-d_stock_mid_blue'>Sales</Link>
            </div>
            <div>
                <ul className="flex justify-between mt-10 pr-10 pl-5 bg-white">
                    {
                        Tabs.map((tab, i) => (
                            <NavLink key={i}
                                to={'/' + url + '/' + tab.slug.toLowerCase()}
                                activeClassName='border-d_stock_mid_blue text-d_stock_mid_blue'
                                className={`text-license
                                border-b-4 rounded border-transparent cursor-pointer py-6 active_sales_tab text-base`}

                            >
                                {tab.name}
                            </NavLink>
                        ))
                    }
                </ul>

                <section>
                    <Switch>
                        <Route path={`/${url}/processing`} exact component={ProcessingOrders} />
                        <Route path={`/${url}/approved`} exact component={ApprovedOrders} />
                        <Route path={`/${url}/rejected`} exact component={RejectedOrders} />
                        <Route path={`/${url}/completed`} exact component={CompletedOrders} />
                        <Route path={`/${url}/`} component={Orders} />
                    </Switch>
                </section>
            </div>
        </div>
    )
}
