import { actionType } from "./actions";

const initialState = {
    count: 0
}

export const AuthReducer = (state = initialState, payload: { type: string, payload: any }) => {
    switch (payload.type) {
        case actionType.ADD:
            return { ...state, count: ++state.count }
        default:
            return state
    }
}


