import React from "react";
import AnalyticsGroup from "../../components/ui-components/analytics-card-group";
import SalesChart from "../../components/charts/saleschart";
import OrderHistory from "../../components/order-history";
import PlatformAcessment from "../../components/platform-acessment";
// import RecentSales from "../../components/recent-sales";

export default function Overview() {



    return (
        <div className="">
            <AnalyticsGroup />
            <section className="md:space-x-8">
                <div className="w-full">
                    <SalesChart />
                </div>
                {/* <div className="md:w-3/12 w-full md:px-5 px-3 recent-sales-container mt-6">
                    <RecentSales />
                </div> */}
            </section>

            <section className="flex p-2.5 mt-8 gap-8">
                <OrderHistory />
                <PlatformAcessment />
            </section>
        </div>
    )
}