import React from 'react';

export class ErrorBoundary extends React.Component {
    state: { hasError: boolean }
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: any, info: any) {
        // Display fallback UI
        this.setState({ hasError: true });
    }

    render() {
        const { children } = this.props as any;
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong......</h1>;
        }
        return children;
    }
}