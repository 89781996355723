import moment from "moment";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory, useParams } from "react-router-dom";
import DrugStocRoundButton from "../../components/ui-components/button-round";
import { Table as TableComponent } from "../../components/ui-components/table";
import Fetchhook from "../../custom-hooks/fetchhook";
import Modal from "../../extra-components/modals";


const Tabletitle = [
    "List of Products",
    "Pack",
    "Product Category",
    "Prescriptions",
    "",
    "",
    "Confirm Availabilty",
    "Enter Amount"
]


export const TeleHealthId = () => {
    const history = useHistory()
    const params = useParams<any>()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, _setLoading] = useState(false)
    const [drugs, setDrugs] = useState([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [requestErrors, setRequestErrors] = useState(false)
    const [showHealthCompanyModal, setShowHealthCompanyModal] = useState(false)
    const [showPractitionerDetails, setShowPracitionerDetails] = useState(false)
    const [showPatientDetails, setShowPatientDetails] = useState(false)
    const [showComments, setShowComments] = useState(false)
    const [details, setDetails] = useState<any>({})
    const [patient, setPatientDetails] = useState<any>({})


    const [FetchData] = Fetchhook()

    const handleSubmit = async () => {
        try {
            console.log(drugs)
            const [response, errors] = await FetchData({
                path: `/telimedicine/precription/${params.id}`,
                methods: 'put',
                data: {
                    ...details,
                    preciption: drugs
                }
            })
            if (errors) {
                setRequestErrors(true)
                return
            }
            console.log(response)
            history.push('/dashboard/tele-health/summary/' + params.id)
        } catch (error) {
            console.log(error)
        }
    }

    const getPrescriptionDetails = async () => {
        try {
            setRequestErrors(false);
            _setLoading(true)
            const [response, errors] = await FetchData({
                path: '/telimedicine/precription/' + params.id,
                methods: 'get'
            });

            if (errors) setRequestErrors(true)
            const patient = await FetchData({
                path: '/telimedicine/patient/' + response.patient,
                methods: 'get'
            })

            setPatientDetails(patient[0])

            console.log(response, patient[0])
            setDetails(response)
            setDrugs(response.preciption)
        } catch (error) {
            console.log(error)
        }
        _setLoading(false);
    };

    useEffect(() => {
        getPrescriptionDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="flex justify-between my-8">
                <div className="text-blue-500 underline cursor-pointer" onClick={() => setShowHealthCompanyModal(true)}>
                    Avon Health & Insurance
                </div>
                <div>
                    Delivery Address:  {patient?.address_1} {patient.city} {patient?.state}
                </div>
            </div>
            <div className="flex justify-between my-5 text-sm">
                Total Number of products:  {drugs.length}
                <div className="flex gap-5">
                    <div className="text-blue-500 underline cursor-pointer" onClick={() => setShowPracitionerDetails(true)}>View Healthcare Practitioner Details</div>
                    <div className="text-blue-500 underline cursor-pointer" onClick={() => setShowPatientDetails(true)}>View Patients Details</div>
                </div>
            </div>

            <table className="w-full md_table flex text-xs md:text-base table-customer">
                <thead className="border-t border-b bg-none  md:bg-none p-2 md:p-0">
                    <TableComponent textAlign="text-left" title={Tabletitle} showSelelect={true} />
                </thead>

                <tbody>
                    {!loading ?
                        drugs?.map((f: any, i: number) => {
                            return (
                                <tr className=" border-b my-3  cursor-pointer text-license hover:bg-header hover:text-d_stock_mid_blue todoLeftImportance" key={i} onClick={() => { }}>
                                    <td className="md:py-4 py-2 px-3 text-center align-center items-center md_table text-sm">
                                        {f.product_name.name || f.other_name}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-center  align-center block md_table text-sm">
                                        {f.pack}
                                    </td>
                                    <td className="md:py-4 py-2 px-3 text-center  align-center block md_table text-sm" >
                                        {f.product_category}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-center px-3 block md_table text-sm" >
                                        {f.dosage}(times)
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-center px-3 block md_table text-sm" >
                                        {f.take}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-center px-3 block md_table text-sm" >
                                        {f.duration}(days)
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-center px-3 block md_table text-sm" >
                                        {f.show ? 'Available' : <input type="checkbox" className="h-6 " defaultChecked={f.product_name.name} />}
                                    </td>
                                    <td className="md:py-4 py-2 text-center  align-center px-3 block md_table text-sm" >
                                        {!f.amount ? <input type="number" className=" border rounded p-2" defaultValue={f.product_name.price} onChange={(e: any) => {
                                            (drugs[i] as any).product_name.price = e.target.value;
                                            setDrugs(drugs)
                                        }} /> : f.amount}
                                    </td>
                                </tr>
                            )
                        })
                        :
                        // loader
                        <tr>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                        </tr>
                    }
                </tbody>
            </table>
            <div className="my-10">
                <p className="text-blue-500 underline cursor-pointer" onClick={() => setShowComments(true)}> Comments</p>
            </div>
            <div>
                <hr />
            </div>
            <section className="flex justify-between my-10">
                <div>
                    Estimated Time of Arrival:
                    <p>
                        {moment(details?.estimated_delivery).fromNow()}
                    </p>
                </div>
                <div className="flex gap-10">
                    <DrugStocRoundButton title={"Cancel"} class="bg-white border px-6 border-black" />
                    <DrugStocRoundButton title={"Done"} class="bg-blue-500 text-white px-6" action={() => handleSubmit()} />
                </div>
            </section>

            {showHealthCompanyModal && <Modal close={() => setShowHealthCompanyModal(false)}>
                <div className=" bg-white p-10 rounded min-w-max" style={{ minWidth: '600px' }}>
                    <h2 className="text-blue-500 text-2xl mb-3 underline">Mark Johnson </h2>
                    <div className="flex gap-10 text-base">
                        <div className="mb-5">
                            <p className="mb-3">Phone Number</p>
                            <p className="mb-3">+32 32 53 45234</p>
                        </div>
                        <div className="mb-5">
                            <p className="mb-3">  Email</p>
                            <p className="mb-3"> {details.email}</p>
                        </div>

                    </div>


                </div>
            </Modal>}


            {showPatientDetails && <Modal close={() => setShowPatientDetails(false)}>
                <div className=" bg-white p-10 rounded min-w-max w-1/3" style={{ width: '600px' }}>
                    <h2 className="text-2xl mb-3 "> {patient.first_name} {patient.last_name} </h2>
                    <div>
                        <p>
                            First Name
                        </p>
                        <p>
                            {patient.first_name}
                        </p>
                    </div>

                    <div className="flex gap-10 text-base my-5">
                        <div className="mb-5">
                            <p className="mb-1">Phone Number</p>
                            <p className="mb-3">{patient.phone_number}</p>
                        </div>
                        <div className="mb-5">
                            <p className="mb-1">  Email</p>
                            <p className="mb-3"> {patient.email}</p>
                        </div>

                    </div>
                    <div>
                        <p>
                            Delivery Address
                        </p>
                        <p>
                            Plot 3 billings way, Oregun, Ikeja, Lagos Nigeria
                        </p>
                    </div>


                </div>
            </Modal>}

            {showComments && <Modal close={() => setShowComments(false)}>
                <div className=" bg-white p-10 rounded min-w-max" >
                    <h2 className="text-2xl mb-3"> Comments </h2>
                    <div style={{ maxWidth: '400px' }}>
                        {details.Instruction}
                    </div>
                </div>
            </Modal>}


            {showPractitionerDetails && <Modal close={() => setShowPracitionerDetails(false)}>
                <div className=" bg-white p-10 rounded min-w-max w-1/3" style={{ width: '600px' }}>
                    <h2 className="text-2xl mb-3 ">{details.practinonal_first_name} {details.practinonal_last_name} </h2>
                    <div>
                        <p>
                            First Name
                        </p>
                        <p>
                            {details.practinonal_first_name} {details.practinonal_last_name}
                        </p>
                    </div>

                    <div className="flex gap-10 text-base">
                        <div className="mb-5">
                            <p className="mb-3">Phone Number</p>
                            <p className="mb-3">{details.practinonal_phone_number}</p>
                        </div>
                        <div className="mb-5">
                            <p className="mb-3">  Email</p>
                            <p className="mb-3"> {details.email}</p>
                        </div>

                    </div>


                </div>
            </Modal>}


            {showPatientDetails && <Modal close={() => setShowPatientDetails(false)}>
                <div className=" bg-white p-10 rounded min-w-max w-1/3" style={{ width: '600px' }}>
                    <h2 className="text-2xl mb-3 "> {patient.first_name} {patient.last_name} </h2>
                    <div>
                        <p>
                            First Name
                        </p>
                        <p>
                            {patient.first_name}
                        </p>
                    </div>

                    <div className="flex gap-10 text-base my-5">
                        <div className="mb-5">
                            <p className="mb-1">Phone Number</p>
                            <p className="mb-3">{patient.phone_number}</p>
                        </div>
                        <div className="mb-5">
                            <p className="mb-1">  Email</p>
                            <p className="mb-3"> {patient.email}</p>
                        </div>

                    </div>


                </div>
            </Modal>}
        </div>
    )
}
