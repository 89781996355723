import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Table as TableComponent } from "../../../components/ui-components/table";
import DrugStocRoundButton from '../../../components/ui-components/button-round';
import Modal from '../../../extra-components/modals';
import Fetchhook from '../../../custom-hooks/fetchhook';
import { useParams } from 'react-router-dom';


const mailSentModal = () => {
    return (
        <Modal close={function (): void {
            throw new Error('Function not implemented.');
        }}>
            Mail sent successfully
        </Modal>
    )
}

export const PrescriptionSummary = () => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showDoneModal, setShowMoreModal] = useState(true)
    const [requestErrors, setRequestErrors] = useState(false)
    const [drugs, setDrugs] = useState([])
    const [FectData] = Fetchhook();
    const params = useParams<any>();

    const Tabletitle = [
        "Product Name",
        "Pack",
        "Product Category",
        "Prescriptions",
        "",
        "",
        "Confirm Availabilty",
        "Enter Amount"
    ]
    const getPrescriptionSummary = async () => {
        try {
            setLoading(true);
            const [response, errors] = await FectData({
                path: '/telimedicine/precription/' + params.id,
                method: 'get'
            });
            if (errors) setRequestErrors(true)
            setDrugs(response.results)
        } catch (error) {
            setRequestErrors(true)
        }
        setLoading(false);
    };

    useEffect(() => {
        getPrescriptionSummary()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (

        <>
            {showDoneModal && mailSentModal}
            <div>
                <h1 className='text-center text-2xl mt-10'>
                    Products Summary
                </h1>
                <section className='flex justify-between my-10'>
                    <div className='text-lg'>
                        Order Details
                    </div>
                    <div className='text-sm font-light flex gap-10'>
                        <div className='underline'>5 Comments / Instructions</div>
                        <div>Monday, Dec 28 2015 at 4.13pm</div>
                    </div>
                </section>
                <table className="w-full md_table flex text-xs md:text-base table-customer">
                    <thead className="border-t border-b bg-none  md:bg-none p-2 md:p-0">
                        <TableComponent textAlign="text-left" title={Tabletitle} showSelelect={true} />
                    </thead>
                    <tbody>
                        {!loading ?
                            drugs?.map((f: any, i: number) => {
                                return (
                                    <tr className=" border-b my-3  cursor-pointer text-license hover:bg-header hover:text-d_stock_mid_blue todoLeftImportance" key={f.account} onClick={() => { }}>
                                        <td className="md:py-4 py-2 px-3 text-center align-top items-center md_table text-sm">
                                            {f.product}
                                        </td>
                                        <td className="md:py-4 py-2 px-3 text-center  align-top block md_table text-sm">
                                            {f.pack}
                                        </td>
                                        <td className="md:py-4 py-2 px-3 text-center  align-top block md_table text-sm" >
                                            {f.product_category}
                                        </td>
                                        <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" >
                                            {f.prescriptions.measure}
                                        </td>
                                        <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" >
                                            {f.prescriptions.times}
                                        </td>
                                        <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" >
                                            {f.prescriptions.duration}
                                        </td>
                                        <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" >
                                            {f.available ? 'Available' : <input type="checkbox" className="h-6" />}
                                        </td>
                                        <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm" >
                                            {!f.amount ? <input type="number" className="h-6 border rounded" /> : f.amount}
                                        </td>
                                    </tr>

                                )
                            })
                            :
                            // loader
                            <tr>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                                <td><Skeleton style={{ height: '30px', margin: '20px', width: '80%' }} className="my-4 px-2 mx-2" count={7} /></td>
                            </tr>
                        }
                        <tr className='border-b my-3  cursor-pointer text-license hover:bg-header hover:text-d_stock_mid_blue todoLeftImportance'>
                            <td className="md:py-4 py-2 px-3 text-center align-top items-center md_table text-sm">Subtotal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>N600,560</td>
                        </tr>
                        <tr className='border-b my-3  cursor-pointer text-license hover:bg-header hover:text-d_stock_mid_blue todoLeftImportance'>
                            <td className="md:py-4 py-2 px-3 text-center align-top items-center md_table text-sm">Delivery Fee</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm">N600,560</td>
                        </tr>
                        <tr className='border-b my-3  cursor-pointer text-license hover:bg-header hover:text-d_stock_mid_blue todoLeftImportance'>
                            <td className="md:py-4 py-2 px-3 text-center align-top items-center md_table text-sm">TotalCost</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="md:py-4 py-2 text-center  align-top px-3 block md_table text-sm">N600,560</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    {
                        drugs?.length > 0 && <p>  No Data Found </p>
                    }

                    {
                        requestErrors && <p className='text-center mt-12'>  Error Occured trying to make this request </p>
                    }

                </div>

                <h1 className='text-2xl mt-10'>Other Details </h1>

                <hr />

                <div className='grid grid-cols-2 justify-between my-7'>
                    <div className='text-sm'>
                        Delivery Address
                    </div>
                    <div>
                        Plot3 Billings Way, Oregun 101233, Ikeja, Lagos Nigeria.
                    </div>
                </div>
                <div className='grid grid-cols-2 justify-between my-7'>
                    <div>
                        Health Care Practitioner
                        Information
                    </div>
                    <div>
                        Estimated Time of Arrival
                    </div>
                </div>
                <div className='grid grid-cols-2 justify-between my-7'>
                    <div>
                        <p>Mark Johnson</p>
                        <p>+234 802 759 0165</p>
                    </div>
                    <div>
                        hour
                    </div>
                </div>

            </div>

            <section>
                <div className="flex gap-10 justify-end">
                    <DrugStocRoundButton title={"Make Changes"} class="bg-white border px-6 border-black" />
                    <DrugStocRoundButton title={"Send"} class="bg-blue-500 text-white px-6" />
                </div>
            </section>



        </>

    )
}
