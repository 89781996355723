export const SortByOption = [
    {
        label: 'Descending',
        value: '-name'
    },
    {
        label: 'Ascending',
        value: 'name'
    },
]


export const searchFields = [
    {
        label: 'Name',
        value: 'name'
    },
    {
        label: 'Email',
        value: 'email'
    },
    {
        label: 'Phone',
        value: 'phone'
    },
    {
        label: 'All',
        value: 'search'
    }
]


export const FilterByOption = [
    {
        label: 'Active',
        value: '1'
    },
    {
        label: 'In Active',
        value: '-1'
    },
    // {
    //     label: 'New Enrolments',
    //     value: 'new-enrolments'
    // },
    {
        label: 'Date',
        value: 'date'
    },
]

export const FilterAccount = [
    {
        label: 'Draft',
        value: 'draft'
    },
    {
        label: 'Open',
        value: 'open'
    },
    {
        label: 'Paid',
        value: 'paid'
    },
    {
        label: 'Overview',
        value: 'overview'
    }
]



export const UserOptions = [
    {
        label: 'Open',
        value: 'open'
    },
    {
        label: 'Edit',
        value: 'edit'
    },
]

export const Tabletitle = [
    "Name",
    "Phone Number",
    "Last Logged In",
    "Last Purchase",
    "Items in Cart",
    "status"
]
