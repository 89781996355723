import React, { useState } from "react";
import { useHistory } from "react-router";
import AlertModals from "../../components/ui-components/alertModals";
import CustomersTables from "../../components/customers";
import NewCustomers from "../../components/new-customers";
import Fetchhook from "../../custom-hooks/fetchhook";
import { toast } from "react-toastify";
import NewCustomer from "./new";
import Modal from "../../extra-components/modals";
import { Table as TableComponent } from "../../components/ui-components/table";
import { GrClose } from "react-icons/gr";


const Tabletitle = [
    "",
    "Products",
    "Quantity",
    "Unit of Measurement",
    "unit price",
    "cost",
    "Subtotal"
]

const itemsInCart = [
    {
        product: "Pharmamax Amlophine - 10mg x 28",
        quantity: "20",
        unit: 1,
        unit_price: 420,
        cost: 380,
        subtotal: 12600
    },
    {
        product: "Pharmamax Amlophine - 10mg x 28",
        quantity: "20",
        unit: 1,
        unit_price: 420,
        cost: 380,
        subtotal: 12600
    }
]


export default function Customers() {
    const [currentTable, setCurrentTable] = useState<"customers" | "enrolments">('customers');
    const [alertModal, showAlertModal] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [itemsInCartModal, setItemsInCartModal] = useState(false)
    const showFormModal = () => {
        setFormModal(true)
    }
    const location = useHistory()
    const [fetchData] = Fetchhook()
    const [showConfirmItems, setConfirmItemsInCartModal] = useState(false)


    const moreAction = (e: string, f?: any) => {
        //collect the velue and  index
        if (e === 'open') {
            location.push('/dashboard/customers/' + f.id)
        } else if (e === 'edit') {
            location.push("/dashboard/customers/edit/" + f.id)
        } else if (e === 'delete') {
            showAlertModal(true)
        }
    }

    const deleteUser = async () => {
    }

    const closeTarget = async (e: any) => {
        const doc = document.querySelector('.__customer_modal')
        if (doc && e.target.contains(doc)) {
            setFormModal(false)
        }
    }


    const exportToCsv = async () => {
        toast.success('Downloading information please wait!')
        try {
            const [response,] = await fetchData({
                methods: 'get',
                path: '/api/admin/export_users?ordering=is_verified',
            })
            const downloadLink = document.createElement("a");
            const blob = new Blob(["\ufeff", response]);
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = "data.csv";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <section className="mt-12 relative">
            {
                alertModal
                &&
                <AlertModals
                    close={() => showAlertModal(false)}
                    action={() => deleteUser()}
                    message={"User will be deleted forever!!"}
                />
            }

            {
                showConfirmItems &&
                <AlertModals
                    close={() => setConfirmItemsInCartModal(false)}
                    action={() => { }}
                    message={"Are You sure you want to remove item from cart"}
                />
            }

            {
                itemsInCartModal && <Modal close={() => setItemsInCartModal(false)}>
                    <div className={"bg-white p-10 rounded font-light"}>
                        <div className={"flex justify-between mb-3"}>
                            <h3 className={"font-thin text-2xl"}> Items in cart</h3>
                            <GrClose size={25} onClick={() => setItemsInCartModal(false)} className={"cursor-pointer"} />
                        </div>
                        <div>

                            <table className="w-full md_table flex table-customer">
                                <thead className=" md:bg-none p-2 md:p-0 text-right">
                                    <TableComponent title={Tabletitle} showSelelect={true} />
                                </thead>
                                <tbody className=" md_table flex md:overflow-hidden overflow-x-auto overflow-y-hidden text-xs">
                                    {itemsInCart.map((item: any, i: number) => {
                                        return (
                                            <tr className="table-body text-base border md:border-none p-2 cursor-pointer hover:bg-gray-100"
                                                key={i}>
                                                <th className="md:py-6 py-4 px-3 text-center align-top block md_table text-xs md:text-base">
                                                    <GrClose size={15} onClick={() => setConfirmItemsInCartModal(true)} className={"cursor-pointer"} />
                                                </th>
                                                <th className="md:py-6 py-4 px-3 text-center align-top block md_table text-xs md:text-base">
                                                    {item.product}
                                                </th>
                                                <th className="md:py-6 py-4 px-3 text-center align-top  block md_table text-xs md:text-sm">
                                                    {item.quantity}
                                                </th>
                                                <th className="md:py-6 py-4 px-3 text-center align-top  block md_table text-xs md:text-sm">
                                                    {item.unit}
                                                </th>
                                                <th className="md:py-6 py-4 px-3 text-center align-top  block md_table text-xs md:text-sm">
                                                    {item.unit_price}
                                                </th>
                                                <th className="md:py-6 py-4 px-3 text-center align-top  block md_table text-xs md:text-sm">
                                                    {item.cost}
                                                </th>
                                                <th className="md:py-6 py-4 px-3 text-center align-top  block md_table text-xs md:text-sm">
                                                    {item.subtotal}
                                                </th>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <hr className={"mt-4"} />

                        <div className={"grid grid-cols-2 justify-between text-2xl gap-4 mt-5"}>
                            <div>
                                Untaxed Amount: N86,000
                            </div>
                            <div className={"text-right"}>
                                Taxed: N0
                            </div>
                            <div>
                                Total: N86,000
                            </div>
                            <div className={"text-right"}>
                                Margin: N6,600
                            </div>
                        </div>
                    </div>
                </Modal>
            }

            {

                <div className={`${currentTable !== 'customers' && 'hidden'}`}>
                    <CustomersTables
                        moreAction={moreAction}
                        currentTable={currentTable}
                        setCurrentTable={setCurrentTable}
                        exportData={exportToCsv}
                        showFormModal={showFormModal}
                        setItemsInCartModal={setItemsInCartModal}
                    />
                </div>
            }


            {
                <div className={`${currentTable !== 'enrolments' && 'hidden'}`}>
                    <NewCustomers
                        moreAction={moreAction} currentTable={currentTable} setCurrentTable={setCurrentTable} exportData={exportToCsv} />
                </div>
            }

            {
                formModal && <div className="fixed w-full top-0 left-0 right-0 dash_board flex justify-center items-center z-50 h-screen" onClick={(e) => closeTarget(e)}>
                    <div className="w-3/4 bg-white my-10 __customer_modal">
                        <NewCustomer />
                        <hr />
                    </div>
                </div>
            }


        </section >
    )
}
