import React, { Component } from 'react';
// @ts-ignore
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }:any ) => <div>{text}</div>;

class SimpleMap extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        // @ts-ignore
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '50vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: '' }}
                    // @ts-ignore
                    defaultCenter={this.props.center}
                    // @ts-ignore
                    defaultZoom={this.props.zoom}
                >
                    <AnyReactComponent
                        lat={59.955413}
                        lng={30.337844}
                        text="My Marker"
                    />
                </GoogleMapReact>
            </div>
        );
    }
}


const mockData = [
    {
        rep:'Yemisi Odutona',
        numberOfPharmacies: 8,
    },
    {
        rep:'Olawale Obianuju',
        numberOfPharmacies: 10,
    },
    {
        rep:'Ovie Otavboruo',
        numberOfPharmacies: 10,
    },
    {
        rep:'James Odu',
        numberOfPharmacies: 5,
    }
]

type tableProps = {
    title: string[]
    data: any[]
}
export const ManagementTable = (props:tableProps) => {
    return (
        <table>
            <thead>
                <tr>
                    { 
                        props.title.map((e, i) => (<th key={i}>
                            <>
                                {e}
                            </>
                        </th>))
                    }
                </tr>
            </thead>
            <tbody>
            {
                props.data.map((e, i) => (
                    <tr key={i}>
                       {Object.keys(e).map((td, j) => (
                            <td key={j}>
                                {e[td]}
                            </td>
                        ) )}
                </tr>))
            }
        <tr>
            <td>
                Total of Reps:50
            </td>
            <td>
                Total of: 1000
            </td>
            <td>
                    <span className='text-blue-500'> View All </span>
            </td>
        </tr>
            </tbody>
        </table>
    )
}


// eslint-disable-next-line import/no-anonymous-default-export
export default function Management () {
    return (
        <>
            <SimpleMap/>
            <div>
                <h3>
                    Geo Details
                </h3>
                <div className='flex'>
                   <ManagementTable 
                        title={['Representatives (Rep’s)', 'Number of Pharamcies/Clinics']} 
                        data={mockData}
                   />
                   <ManagementTable 
                        title={['Representatives (Rep’s)', 'Number of Pharamcies/Clinics']} 
                        data={mockData}
                   />
                </div>
            </div>
        </>
    )
}