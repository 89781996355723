import React, { useState } from 'react'
import ModalInput from './ui-components/modal-input'
import validator from 'validator';
import DrugStocRoundButton from './ui-components/button-round';



export default function Password( {closeModal}:any) {
    const [userData, setUserData] = useState<any>({

        password: '',
        newPassword: ''
    });
    const validationObject = {
        password: validator.isEmpty(userData.password),
        newPassword: validator.isEmpty(userData.newPassword)

    }
    return (
        <div className=' bg-white rounded' style={{ width: '30%' }}>
            <div className="py-10" >
                <p className="text-xl font-thin text-nav text-center">Reset Password</p>
                <p className='mt-5 text-label text-sm text-center w-5/6 mx-auto'>Reset customer’s password. Please advice user
                    to change password after successful onboarding the platform.</p>
            </div>

            <div className="md:w-4/5 mx-auto w-full ">

                <ModalInput
                    type={"text"}
                    value={userData.password}
                    getValue={(e) => setUserData({ ...userData, password: e })}
                    label={"Old Password"}
                    class='bg-file_two'
                    border='0px'


                />
                {validationObject.password && <p className="text-red-500 text-xs mt-2"> required </p>}
            </div>
            <div className="md:w-4/5 mt-8 mx-auto w-full ">

                <ModalInput
                    type={"text"}
                    value={userData.newPassword}
                    getValue={(e) => setUserData({ ...userData, password: e })}
                    label={"New Password"}
                    class='bg-file_two'
                    border='0px'


                />
                {validationObject.newPassword && <p className="text-red-500 text-xs mt-2"> required </p>}
            </div>

            <div className="text-right flex space-x-6 md:justify-end justify-between md:max-w-max ml-auto mr-8 mb-8">
                <DrugStocRoundButton
                    title="Cancel"
                    class={"bg-transparent border-modal_label border text-white text-sm md:w-auto justify-center mt-5 md:ml-auto  m-0  max-w-max"}
                    paddingY='py-2'
                    paddingX='px-9'
                    textColor={'#484848'}
                    action={closeModal}

                />
                <DrugStocRoundButton
                    title="Done"
                    class={"bg-d_stock_card_blue text-white text-sm md:w-auto justify-center mt-5 md:ml-auto  m-0  max-w-max"}
                    textColor={"white"}
                    paddingY='py-2'
                    paddingX='px-9'

                />
            </div>
        </div>
    )
}
