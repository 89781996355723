// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Open',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Open',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Open',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Open',
    },
    {
        account: 'Rembem Pharmacy',
        account_date: '12/03/2021',
        customer_name: 'John Doe',
        total: '$1,000.00',
        sources_document: 'S033022',
        status: 'Drafts',
    },

]


export const products = [
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
    {
        product_name: "Pharmamax Amlophine - 10mg x 28",
        quantity: 1,
        unit_of_measurement: "unit(s)",
        unit_price: 420,
        cost: 380,
        sub_total: "₦12,600.00",
    },
]