import React from 'react'

type props = {
    children: React.ReactNode
    close: () => void
}

export default function Modal(Props: props) {
    const { children, close } = Props

    const closeModal = (event: any) => {
        // const body = document.querySelector('#modal-component')
        const children = document.querySelector('.modal_children')
        if (event?.target?.contains(children)) {
            close();
        }
    }

    return (
        <div className="fixed w-full top-0 left-0 right-0 dash_board flex justify-center items-center z-40 h-screen" onClick={(e) => closeModal(e)} id='modal-component'>
            <div className="modal_children ">
                {children}
            </div>
        </div>
    )
}
