import React, { useState } from 'react'

type inputProps = {
    label?: string,
    placeholder?: string,
    type: string,
    getValue(word: string): void,
    error?: boolean
    value?: any
    class?: string
    size?: string
    border?: string
    otherLabel?: JSX.Element
}
export default function ModalInput(props: inputProps) {
    const [dirty, setDirty] = useState(false)
    return (
        <div>
            {props.label ?
                <div className="md:mb-2 my-2">
                    <label htmlFor={props.label} className={`font-light text-base ${props.error && dirty ? 'text-red-500' : 'text-nav'}`}>
                        {props.label} {props.otherLabel}
                    </label>
                </div> :
                ''}
            <input
                onFocus={() => setDirty(true)}
                type={props.type}
                name={props.label}
                placeholder={props.placeholder}
                onInput={(e) => props.getValue((e.target as any).value)}
                className={`w-full px-4 py-3 rounded outline-none h-10 font-thin text-sm ${props.error && dirty ? 'border-red-500' : 'border-modal_input'} ${props.class} ${props.border ? props.border : 'border'}`}
                value={props.value}

            />
        </div>
    )
}
