import { Route, Switch, useRouteMatch } from "react-router-dom";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import Notfound from "../pages/notfound";


export default function Default() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let { path, url } = useRouteMatch();
    return (
        <div>
            {/* default Route */}
            <Switch>
                <Route path={`${url}/login`} exact component={Login} />
                <Route path={`${url}/signup`} exact component={Signup} />
                <Route path="*" component={Notfound} />
            </Switch>
        </div>
    )
}