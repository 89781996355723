export default function Initials(props: {
    initials: string,
    font?: number,
    size?: 'md' | 'lg'
}) {
    return (
        <div className={` ${props.size === 'lg' ? 'h-16 w-16' : 'md:w-10 md:h-10 h-8 w-8'} rounded-full flex items-center justify-center`} style={{ background: '#BEC8F7' }}>
            <p className="uppercase text-center font-bold" style={{ color: '#2C4DA7', fontSize: props.font || '14px' }}>
                {props.initials.split(" ")[0][0]}
                {props.initials.split(" ")[1][0]}
            </p>
        </div>
    )
}
