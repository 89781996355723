import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { AuthReducer } from "./authentication/reducer";
import { responsiveReducer as ResReducer } from './responsive/reducer'
// import { composeWithDevTools } from 'redux-devtools-extension'
import ReduxThunk from 'redux-thunk'

const rootReducer = combineReducers({ auth: AuthReducer, responsive: ResReducer })

export const store = createStore(rootReducer, compose(
    applyMiddleware(ReduxThunk),
    // composeWithDevTools()
))