type tr = {
    tr: any[]
}

type tableProps = {
    title: string[],
    body?: tr[],
    showSelelect?: boolean,
    textAlign?: string
}

export const Table = (props: tableProps) => {
    const THead = props.title.map((e, i) => {
        return (
            <th className={`font-bold  py-6 px-6 text-label text-left text-xs md:text-base block md_table ${props.textAlign ? props.textAlign : 'text-left'}`} key={i}>
                {e}
            </th >
        )
    })

    return (
        <tr>
            {THead}
        </tr>

    )
}

// export const TableBody = (props: ) => {

// }