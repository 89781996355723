// import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import Avatars from '../extra-components/avatars'
import { useEffect, useState } from "react";
import Fetchhook from "../custom-hooks/fetchhook";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

export default function OrderHistory() {


    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<any>([])
    const [fetchData] = Fetchhook();


    const getAllData = async () => {
        try {
            setLoading(true)
            const [response,] = await fetchData({
                methods: 'get',
                path: '/api/admin/sales?dpage=' + 1,
            })
            setData(response.results.slice(0, 6))
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    useEffect(() => {
        getAllData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const history = useHistory()
    return (
        <div className="w-2/3 __order_shadow bg-white pb-8">
            <h1 className='my-4 text-d_stock_sec_blue text-sm px-9'>Order History </h1>
            <table className="table-auto w-full ">
                < tbody className=''>
                    <tr className="w-full bg-gray-300 bg-opacity-25 ">
                        <td className="text-sm text-order  py-3 pl-9">Customer</td>
                        <td className="text-sm text-order  py-3">Order No.</td>
                        <td className="text-sm text-order  py-3">Purchased Method</td>
                        <td className="text-sm text-order  py-3">Purchased on </td>
                    </tr>
                    {/* {'₦' + new Intl.NumberFormat("en").format(3000)} sales */}
                    {loading && <tr>
                        <td><Skeleton count={3} height={'30px'} style={{ margin: '20px' }} /></td>
                        <td><Skeleton count={3} height={'30px'} style={{ margin: '20px' }} /></td>
                        <td><Skeleton count={3} height={'30px'} style={{ margin: '20px' }} /></td>
                        <td><Skeleton count={3} height={'30px'} style={{ margin: '20px' }} /></td>
                        <td><Skeleton count={3} height={'30px'} style={{ margin: '20px' }} /></td>
                        <td><Skeleton count={3} height={'30px'} style={{ margin: '20px' }} /></td>
                    </tr>}
                    {!loading && data.map((e: any, i: number) => <tr>
                        <td className='px-9 py-5 ' key={e.customer + i}>
                            <div className='flex items-center'>
                                <Avatars size='medium' name={`${e.customer}`} radius={'circle'} />
                                <div className='ml-4'>
                                    <p className='text-sm text-modal_label'> {e.customer}  </p>
                                    <div className='text-xs mt-auto text-license flex items-center '>
                                        <TransactionDot typeT={e.status} /> {e.status} </div>
                                </div>
                            </div>
                        </td>
                        <td className='text-sm text-license'>
                            {e.id}
                        </td>
                        <td className='text-sm text-license'>
                            cheque
                        </td>
                        <td className='text-sm text-license'>
                            {moment(e.created_date).fromNow()}
                        </td>
                    </tr>
                    )}
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                            className="text-xs text-license cursor-pointer"
                            onClick={() => history.push('/dashboard/sales')}>
                            View all orders
                        </td>
                    </tr>
                </tbody >
            </table >
        </div>
    )
}


enum TransactionType {
    draft = 'bg-yellow-500',
    success = 1
}

// @ts-ignore
export const TransactionDot = ({ typeT }) => {
    return (
        <div className={'w-2 h-2 ' + (TransactionType[typeT] ? TransactionType[typeT] : 'bg-d_stock_card_blue') + ' rounded-full mr-2'}>
        </div>
    )
}
