/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'

export default function Dropdown(props: {
    label: any, options: {
        label: any, value: string
    }[],
    class?: string,
    handleSelect?: any
    full?: boolean
    error?: boolean
    align?: "left" | "right" | "center"
    hover?: boolean
    activeClass?: boolean
}) {

    const [active, setActive] = useState('')
    const handleClick = (value: string) => {
        props.activeClass === undefined ? setActive(value) : !props.activeClass || setActive(value)
        props.handleSelect ? props.handleSelect(value) : console.log(value)
    }
    return (
        <Menu as="div" className={`relative inline-block  text-left  ${props.full ? 'w-full' : ''}`}>

            <Menu.Button className={props.class}>
                {props.label}
            </Menu.Button>


            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-left absolute right-0 mt-2 w-full min-w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50" style={{
                    maxHeight: '296px',
                    overflow: 'scroll'
                }}>
                    {props.options.map((d, i) => {
                        return (
                            <Menu.Item key={i}>
                                <div
                                    className={
                                        `block px-4 py-2 z-30 text-sm cursor-pointer bg-white ${props.hover === undefined ? 'hover:bg-gray-100' : !props.hover || 'hover:bg-gray-100'} ${'text-' + (props.align || 'right')} ${active === d.value ? 'bg-gray-300' : 'bg-white'}`
                                    }
                                    onClick={() => handleClick(d.value)}
                                >
                                    {d.label}
                                </div>
                            </Menu.Item>
                        )
                    })}
                </Menu.Items>
            </Transition >
        </Menu >
    )
}
